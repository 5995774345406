import React from 'react'
import styled from 'styled-components';

import MightyForYouWeb from '../../assets/web/mighty-for-you-web.svg'
import MightyForYouMobile from '../../assets/mobile/mighty-for-you-mobile.svg'

import Content from '../../components/core/Content'
import { useWindowWidth } from '@react-hook/window-size'
//@ts-ignore
import Fade from 'react-reveal/Fade'

const CustomContainer = styled.div`
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 48px;

    @media screen and (max-width: 1024px) {
        padding: 24px;
    }

    .row {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1024px) {
            margin-top: 30px;
            flex-direction: column;
        }

        .col {
            width: 48%;
            @media screen and (max-width: 1024px) {
                max-width: 620px;
                flex-direction: column;
                width: 100%;
            }
        }
    }
`;

export default function Mighty() {
    const width = useWindowWidth();
    const mobile = width <= 768;

    return (
        <CustomContainer id="Mighty">
            <div className='row'>
                <div className='col'>
                    <Fade bottom>
                        <Content
                            title="Mighty is for you."
                            body="Academia is intense. It can be messy, but also amazing. Mighty gives you a space where you can take control of your social and academic choices."
                            caption="No profs. No grades. Just students. 💪"
                            titleHighlight="BrandLight"
                            mb={6}
                        />
                    </Fade>
                </div>
                <div className='col'>
                    <Fade bottom>
                        <img src={mobile ? MightyForYouMobile : MightyForYouWeb} style={{ width: '100%', objectFit: "contain" }} />
                    </Fade>
                </div>
            </div>
        </CustomContainer>
    )
}
