import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme'
import { Link as ReactLink } from 'react-router-dom'
import Mighty from '../icons/Mighty'

const Container = styled.div`
    background-color:${theme.palette.Ink};
    padding:3.125rem ;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
    text-align:center;
`
const Link = styled(ReactLink)`
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.18;
    color:${theme.palette.White};
    margin-top:0.75rem;
    display:block;
    :hover{
        color:${theme.palette.White};
        text-decoration:underline solid ${theme.palette.White};
    }
`
const Email = styled.a`
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.18;
    color:${theme.palette.White};
    margin-top:0.75rem;
    display:block;
    :hover{
        color:${theme.palette.White};
        text-decoration:underline solid ${theme.palette.White};
    }
`
const Company = styled.p`
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.18;
    color:${theme.palette.White};
    margin-top:2.25rem;
    margin-bottom:0;
`
export default function Mobile() {
    return (
        <Container>
            <div>
                <ReactLink to="/">
                    <Mighty />
                </ReactLink>
                <Company> Sandbox Labs Research & Development</Company>
            </div>
            <div style={{ marginTop: "3.125rem" }}>
                <p style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "1.25rem", lineHeight: 1.18, color: theme.palette.White, marginBottom: "1.5rem" }}>Legal</p>
                <Link to="/terms-of-service">Terms of Service</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Email href="mailto:support@sandboxlabs.ca">Contact Us</Email>
            </div>
        </Container>
    )
}