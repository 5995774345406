import React from 'react'
import theme from '../../Theme'
import Box from './Box'
import Text from './Text'
type Props = {
    title: string
    body: string
    caption: string
    titleHighlight: "BrandLight" | "OrangeLight" | "BlueLight"
    mb?: number
}
export default function Content({ title, body, caption, titleHighlight, mb }: Props) {
    return (
        <Box>
            <Text
                varient="Header2"
                color="Ink"
            >
                <mark
                    style={{
                        backgroundImage: `linear-gradient(to top,${theme.palette[titleHighlight]} 50%, transparent 50%)`,
                        backgroundColor: "transparent",
                        color: theme.palette.Ink,
                        padding: 0,
                    }}>

                    {title}
                </mark>
            </Text>
            <Box mt={9} mb={mb ? mb : 0}>
                <Text
                    varient="Body1"
                    color="Ink"
                >
                    {body}
                </Text>
                <Box mt={3}>
                    <Text
                        varient="Body1"
                        fontWeight={700}
                        color="Ink"
                    >
                        {caption}
                    </Text>

                </Box>
            </Box>
        </Box >
    )
}
