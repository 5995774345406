import React from 'react'
import styled from 'styled-components';
import { useWindowWidth } from '@react-hook/window-size';
import Accordion from './Accordion'
import Carousel from './Caurosel'

const Container = styled.div`
    width: 100%;
`;

export default function About() {
    const width = useWindowWidth();
    const mobile = width <= 768
    return (
        <Container>
            { mobile ? <Accordion /> : <Carousel /> }
        </Container>
    )
}
