const theme = {
    palette: {
        Ink: "#202020",
        White: "#FFFFFF",
        Brand: "#6839F1",
        BrandLight: '#E3D9FF',
        BlueLight: '#D0F0EE',
        OrangeLight: '#FFD99F',
        GreenLight: '#B6E8E4',
        Green: '#0EC0B7',
        Red: "#FF5454",
    },
    weights: {
        bold: 700,
        semiBold: 600,
        medium: 500,
        regular: 400
    },
    spacing(int: number) {
        return int * 4
    }
}

export default theme