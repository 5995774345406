import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

const StyledContainer = styled(Container)`
    line-height: 1.5;
    font-family: "Inter", Arial, Helvetica, sans-serif;

    .title {
        text-align: center;
        font-weight: 700;
        margin-bottom: 16px;
    }

    h3 {
        margin-top: 32px;
    }
`;

export default function Privacy() {
    return (
        <StyledContainer fluid="lg" className="pb-5" style={{ paddingTop: "7.25rem" }}>
            <h2 className='title'>Mighty Privacy Policy</h2>

            <p className='last-updated'>Last Updated: <strong>February 19, 2021</strong></p>
            <p>Sandbox Labs – Research and Development Inc. (“Sandbox”, “us”, “our”, or “we”) values and respects your privacy. We have prepared this privacy policy to explain the manner in which we collect, use and disclose personal information through our website, located at mightyapp.ca (the “Website”), the Mighty mobile application (the “App”) (collectively, our “Online Services”), and other interactions with you.</p>
            
            <h3>TABLE OF CONTENTS</h3>
            <ul>
                <li>Collection And Use Of Personal Information</li>
                <li>Sharing Of Personal Information</li>
                <li>Information About Our Website</li>
                <li>Safeguards And Retention</li>
                <li>Access To Information</li>
                <li>Updates To The Privacy Policy</li>
                <li>Contact Us</li>
            </ul>

            <h3>COLLECTION AND USE OF PERSONAL INFORMATION</h3>
            <p>We may collect personal information when you:</p>
            <ul>
                <li>Create an account</li>
                <li>Sign up to receive push notifications</li>
                <li>Use the chat features of the App, including the Anonymous Chat</li>
                <li>Use the App such as App usage information and analytics</li>
                <li>Sign up for marketing communications</li>
                <li>Connect your social media account</li>
                <li>Contact us with a comment, question, or complaint</li>
            </ul>

            <p><strong>Create an Account:</strong> You do not have to register to visit or use our Website, but an account is required to use our App. If you choose to create an account, we require certain information for the purposes of administering your account, including your first and last name, email address, and a password that you create. We strongly recommend that you do not disclose your password to anyone, and that you select a long and unique password that is not used with services or accounts. We will never ask you for your password in any unsolicited communication (such as letters, phone calls, or email messages). If you become aware of any unauthorized access to or use of your account, you are required to notify us immediately. When your account has been created, you can choose to share additional information with us, like classes you are enrolled in, your expected year of graduation, or your interests and personality traits. Providing us with this information will allow you to take advantage of certain features of the App.</p>

            <p><strong>Push Notifications:</strong> If you provide your consent to receive push notifications, we may display push notifications on your mobile device such as welcome messages, product features and account-related notifications. If you wish to stop receiving push notifications from us, you can turn off push notifications for the mobile application in the settings of your mobile device.</p>

            <p><strong>Chat:</strong> In using the App, you will have access to our Anonymous Chat (the “Chat”) feature. While using the Chat, you will be anonymous to other users, but will still be identifiable to us. We may monitor the Chat from time to time to ensure there is no misconduct or abuse. In these cases, we will be able to identify offending users and restrict their access or remove them from the platform. For more information on appropriate uses of the App please refer to our <Link to='/terms-of-service'>Terms of Use</Link>.</p>
        
            <p><strong>App Usage Information &amp; Analytics:</strong> As with many applications, certain limited data is required for the App to function on your device. This data includes the type of device hardware and operating system, unique device identiﬁer, IP address, language settings, and the date and time the App accesses our servers. We use this information to help us understand the activity on our App, to monitor and improve our App, and to tailor your in-App experience. In addition, we may use third party service providers to collect analytical information about your use of the App, such as the App features used and time spent on the App, to help us tailor your in-app experience, improve our products and the quality of our App, and to manage and analyze data in order to better understand our users.</p>
        
            <p><strong>Marketing Communications:</strong> If you sign up for an account, you are consenting to receive marketing communications from us. You can unsubscribe at any time by clicking the “unsubscribe” link included at the bottom of each email. Alternatively, you can opt out of receiving email marketing communications by contacting us at the contact information provided in the “Contact Us” section below. Please note that you may continue to receive transactional or account-related communications from us.</p>
            
            <p><strong>Connecting Social Media Account:</strong> If you choose to connect your Instagram or Spotify account, we will collect your username. You authorize us to access certain account information (consistent with your social media account privacy settings), including your name and email address as well as any public photos you post on your feed, or music preferences. We do not have access to or collect any information contained in the direct messages of your social media account. You may disconnect your social media accounts at any time in your App settings. We do not have control over the manner in which your personal information is treated on these social media websites. Please review the privacy policies that apply to those social media accounts for more information.</p>
            
            <p><strong>Contact Us:</strong> When you contact us with a comment, question, or complaint through email, you may be asked for information that identifies you, along with additional information we need to help us promptly answer your question or respond to your comment. We may retain this information to assist you in the future and to improve our customer service and service offerings.</p>

            <h3>SHARING OF PERSONAL INFORMATION</h3>
            <p>We do not share or disclose your personal information to third parties without your consent, except as described below or as required or permitted by applicable law:</p>
            <ul>
                <li><p><strong>Service Providers:</strong> Your personal information may be transferred (or otherwise made available) to third parties that provide services on our behalf. We use service providers to provide services such as hosting the Website and provide infrastructure for our App. Our service providers are only provided with the information they need to perform their designated functions and are not authorized to use or disclose personal information for their own marketing or other purposes. Our service providers may be located in the US, Canada or other foreign jurisdictions. In the event that personal information is transferred to the US or another foreign jurisdiction, it will be subject to the laws of that jurisdiction and may be disclosed to or accessed by the courts, law enforcement, and governmental authorities in accordance with those laws. Our service providers are given the information they need to perform their designated functions and are not authorized to use or disclose personal information for their own marketing or other purposes.</p></li>
                <li><p><strong>Legal and Compliance:</strong> We and our Canadian, US, and other foreign service providers may disclose your personal information in response to a search warrant or other legally valid inquiry or order, or to another organization for the purposes of investigating a breach of an agreement or contravention of law or detecting, suppressing, or preventing fraud, or as otherwise may be required or permitted by applicable Canadian, US or other law or legal process, which may include lawful access by US or foreign courts, law enforcement, or other government authorities. Your personal information may also be disclosed where necessary for the establishment, exercise, or defense of legal claims and to investigate or prevent actual or suspected loss or harm to persons or property.</p></li>
                <li><p><strong>Sale of Business:</strong> We may transfer any information we have about you as an asset in connection with a proposed or completed merger, acquisition, or sale (including transfers made as part of insolvency or bankruptcy proceedings) involving all or part of the Company or as part of a corporate reorganization or other change in corporate control.</p></li>
            </ul>

            <h3>INFORMATION ABOUT OUR WEBSITE</h3>

            <p><strong>Visiting our Website:</strong> In general, you can visit our Website without telling us who you are or submitting any personal information. However, we collect the IP (Internet protocol) addresses of all visitors to our Website and other related information such as page requests, browser type, operating system, and average time spent on our Website. We use this information to help us understand our Website activity and to monitor and improve our Website.</p>

            <p><strong>Cookies:</strong> Website uses a technology called “cookies”. A cookie is a tiny element of data that our Website sends to a user’s browser, which may then be stored on the user’s hard drive so that we can recognize the user’s computer or device when they return. You may set your browser to notify you when you receive a cookie or to not accept certain cookies.</p>

            <p><strong>Analytics:</strong> We may use a third party such as Google Analytics to help us gather and analyze information about the areas visited on the Website (such as the pages most read, time spent, search terms, and other engagement data) in order to evaluate and improve the user experience and the Website. For more information or to opt out using the Google Analytics opt-out browser add-on, see “<a href='https://policies.google.com/technologies/partner-sites?hl=en-US' target='blank'>How Google uses data when you use our partners’ sites or apps</a>” and “<a href='https://support.google.com/analytics/answer/9019185?hl=en' target='blank'>Google Analytics and Privacy</a>”.</p>

            <h3>SAFEGUARDS AND RETENTION</h3>

            <p>We have implemented reasonable administrative, technical, and physical measures in an effort to safeguard the personal information in our custody and control against theft, loss and unauthorized access, use, modification, and disclosure. We restrict access to personal information on a need-to-know basis to employees and authorized service providers who require access to fulfil their job requirements.</p>

            <h3>ACCESS TO INFORMATION</h3>

            <p>Subject to limited exceptions under applicable law, you have the right to access, update and correct inaccuracies in your personal information in our custody and control. You may request access, updating and corrections of inaccuracies in your personal information in our custody or control by emailing or writing to us at the contact information set out below. We may request certain personal information for the purpose of verifying the identity of the individual seeking access to his or her personal information records.</p>

            <h3>UPDATES TO THE PRIVACY POLICY</h3>

            <p>We may update this privacy policy periodically to reflect changes to our privacy practices. We encourage you to periodically review this page to ensure you are familiar with those changes. We will indicate at the top of this privacy policy when it was most recently updated.</p>

            <h3>CONTACT US</h3>
            
            <p>If you have any questions or comments about this privacy policy or the manner in which we or our service providers treat your personal information, or to request access to your personal information in our records, please contact us at: support@sandboxlabs.ca</p>

        </StyledContainer>
    )
}
