import { useWindowWidth } from '@react-hook/window-size';
import React from 'react'
import Desktop from './Desktop';
import Mobile from './Mobile';

export default function NavBar() {
    const width = useWindowWidth();
    const mobile = width <= 1024
    return mobile ? <Mobile /> : <Desktop />
}
