import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import MightyIcon from '../icons/Mighty'
import { HashLink } from 'react-router-hash-link';
import theme from '../../Theme';
import { useLocation } from 'react-router-dom';
import Close from '../icons/Close';
import Hamburger from '../icons/Hamburger';
import Background from '../../assets/mobile/NavbarBackground.png'
import { isMobile, isIOS, isAndroid } from 'react-device-detect'
import mixpanel from 'mixpanel-browser'
const NavContainer = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:1;
    
`
const Nav = styled.div<{ top: boolean, home: boolean, expanded: boolean }>`
    height: 70px;
    padding: 0 30px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    transition: all 0.2s ease-in-out;
    background-color:${({ top, home, expanded }) => top && home && !expanded ? "transparent" : theme.palette.White};

    @media screen and (max-width: 768px) {
        padding: 0 24px;
    }

    .close-container, .hamburger-container {
        cursor: pointer;
    }
`
const Collapsible = styled.div`
    width:100%;
    height: calc(100vh - 70px);
    background-color: rgba(255,255,255,1);
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
        display: flex;
        flex-direction: column;
        padding-bottom: 90px;
    }
`
const Link = styled(HashLink)`
    font-family:Inter;
    font-weight:600;
    color:${theme.palette.Brand};
    font-size:1.25rem;
    line-height:1.2;
    margin-top:40px;
    text-align: center;
    
    :hover{
        color:${theme.palette.Brand};
        text-decoration:underline solid ${theme.palette.Brand};
    }
`
const DownloadButton = styled.div`
    padding: 1rem 2rem;
    margin-top:40px;
    border-radius:4px;
    background-color:${theme.palette.Brand};
    color:${theme.palette.White};
    text-align:center;
    font-family:Inter;
    font-weight:600;
    font-size:1rem;
    line-height:1.19;
    cursor: pointer;
    user-select: none;
`
const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

let downloadLink: string
if (isMobile && isAndroid) downloadLink = "https://play.google.com/store/apps/details?id=com.sandboxlabs.Mighty"
if (isMobile && isIOS) downloadLink = "https://apps.apple.com/ca/app/mighty/id1551423127"

export default function Mobile() {
    const [scrolledTop, setScrolledTop] = useState(true)
    const { pathname } = useLocation()
    const [expanded, setExpanded] = useState(false)
    const home = pathname === "/"

    useEffect(() => {
        const breakPoint = 40
        const onScroll = () => {
            const st = window.pageYOffset || document.documentElement.scrollTop
            setScrolledTop(st < breakPoint)
        }
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return (
        <NavContainer>
            <Nav top={scrolledTop} home={home} expanded={expanded}>
                <HashLink smooth={true} to="/#">
                    <MightyIcon height={27} width={148} color={scrolledTop && !expanded && home ? theme.palette.White : theme.palette.Brand} />
                </HashLink>
                {
                    expanded ?
                        <div className='close-container' onClick={() => setExpanded(false)}>
                            <Close color={theme.palette.Brand} />
                        </div>
                        :
                        <div className='hamburger-container' onClick={() => setExpanded(true)}>
                            <Hamburger color={scrolledTop && home ? theme.palette.White : theme.palette.Brand} />
                        </div>
                }
            </Nav>
            { expanded ?

                <Collapsible>
                    <div className='content'>
                        <Link onClick={() => setExpanded(false)} scroll={scrollWithOffset} to="/#Mighty"> Why Mighty?</Link>
                        <Link onClick={() => setExpanded(false)} scroll={scrollWithOffset} to="/#Connections">Smart Connections</Link>
                        <Link onClick={() => setExpanded(false)} scroll={scrollWithOffset} to="/#Progress">Academic Progress</Link>
                        <Link onClick={() => setExpanded(false)} scroll={scrollWithOffset} to="/#About">About Us</Link>
                        <a href={downloadLink} target="_blank" rel="noreferrer"
                            onClick={() => {
                                setExpanded(false)
                                mixpanel.track(isIOS ? "Download-IOS" : "Download-Android", { location: "nav" })
                            }}
                        >
                            <DownloadButton>Download</DownloadButton>
                        </a>
                    </div>
                </Collapsible>
                : null
            }
        </NavContainer>
    )
}