import React from 'react'
import Pablita from '../../assets/core/Pablita-Party.png'
import Text from '../../components/core/Text'
import AppleStore from '../../components/core/AppleStore'
import GooglePlay from '../../components/core/GooglePlay'
import theme from '../../Theme'
import { Container, Row, Col } from 'react-bootstrap'
import { useWindowWidth } from '@react-hook/window-size'
import Background from '../../assets/mobile/Footer.png'
import mixpanel from 'mixpanel-browser'
import styled from 'styled-components'

const OuterContainer = styled.div`
    .buttons-container {
        display: flex;
        justify-content: center;
        margin: 3em 0;
    }

    @media screen and (max-width: 768px) {
        .buttons-container {
            flex-direction: column;
            margin-bottom: -25px;
        }

        .apple-store {
            margin-bottom: 1em;
        }
    }
`;

const ButtonWrap = styled.a`
        margin-right: 1rem;
        margin-left: 1rem;
    @media screen and (max-width:768px){
        margin-bottom:1rem;
        margin-left: 0;
        margin-right: 0;
    }
`

export default function Download() {
    const width = useWindowWidth();
    const mobile = width <= 768
    return (
        <OuterContainer>
            <Container fluid="lg" className="mt-5" style={{ marginBottom: mobile ? '' : "10rem" }}>
                <Row className="justify-content-center text-center">
                    <Col xs={10} lg={8}>
                        <div>
                            <img src={Pablita} style={{ width: "100%", objectFit: "contain" }} />
                        </div>
                        <div className="mt-lg-3 mt-4">
                            <Text varient="Caption1" color="Ink">
                                Be more than just a student number.
                        </Text>
                        <Text varient="Caption1" color="Ink" id="Download">
                            <mark
                                style={{
                                    backgroundImage: `linear-gradient(to top,${theme.palette["BrandLight"]} 50%, transparent 50%)`,
                                    backgroundColor: "transparent",
                                    color: "inherit"
                                }}>
                                Download Mighty now
                            </mark>
                        </Text>
                        </div>
                        <div className="buttons-container" >
                            <div className='apple-store' onClick={() => mixpanel.track("Download-IOS", { location: "footer" })}>
                                <ButtonWrap href="https://apps.apple.com/ca/app/mighty/id1551423127" target="_blank">
                                    <AppleStore size={50}/>
                                </ButtonWrap>
                            </div>
                            <div onClick={() => mixpanel.track("Download-Android", { location: "footer" })}>
                                <ButtonWrap href="https://play.google.com/store/apps/details?id=com.sandboxlabs.Mighty" target="_blank">
                                    <GooglePlay size={50}/>
                                </ButtonWrap>
                            </div>

                        </div>
                        {
                            !mobile &&
                            <div className="mt-2">
                                <Text varient="Caption2" color="Ink">
                                    Beta testing at UofT right now
                        </Text>

                            </div>
                        }
                    </Col>
                </Row>

            </Container>
            {
                mobile &&
                <div style={{ backgroundImage: `url(${Background})`, backgroundSize: "cover", backgroundPosition: "right top", height: "57vw", position: "relative" }}>
                    <Text varient="Caption2" color="White" style={{ position: "absolute", right: 0, left: 0, bottom: "20%", textAlign: "center" }}>
                        Beta testing at UofT right now
                 </Text>

                </div>
            }
        </OuterContainer>
    )
}
