import mixpanel from 'mixpanel-browser'
import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../../Theme'
import Text from './Text'
import NextButton from '../icons/NextButton'
//@ts-ignore
import Fade from 'react-reveal/Fade'
const Container = styled.div`
    background-color:${theme.palette.Brand};
   
`
const Wrapper = styled.div`
    width:50%;
    margin:auto;
    padding:40px 0; 
    @media screen and (max-width:768px){
        width:70%;
    }
    @media screen and (max-width:425px){
        width:90%;
    }
`
const Divider = styled.div`
    @media screen and (max-width:768px){
        width:70%;
    }
    @media screen and (max-width:768px){
        width:50%;
        border-top:3px white solid;
        margin:auto;
    }
`
const Background = styled.div`
    background-color:${theme.palette.White};
    border: 1px solid #A6A6A6;
    border-radius: 6px;
    padding:10px 15px 10px 19px;
    margin:15px 0;
`
const FormContainer = styled.div`
    display:flex;
    flex-direction:row;
   
    justify-content:space-between;
`
const Form = styled.input`
    border:0;
    :focus{
        outline:0;
    };
    margin-right:1rem;
    width:55%;
`
const Submit = styled.button`
    border-radius: 6px;
    background-color:${theme.palette.Brand};
    padding: 1rem 0.5rem;
    color:${theme.palette.White};
    text-align:center;
    width:45%;
    font-family:'Poppins';
    font-weight:600;
    font-size:18px;
    line-height:22px;
    border:0;
    max-width:250px;
`
const SucessAlert = styled.div`
    border-radius:6px;
    background-color:${theme.palette.GreenLight};
    text-align:center;
    vertical-align:middle;
    padding:15px 20px;
`
const ErrorAlert = styled.div`
    border-radius:6px;
    background-color:rgba(255, 84, 84, 0.4);
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding:15px 20px;
`
export default function Email() {
    const [email, setEmail] = useState("")
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const validateEmail = () => {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setSuccess(true)
            mixpanel.track('Subscribe to emails',{email})
            mixpanel.identify()
            mixpanel.people.set({
                'appName':"LandingPage",
                '$email':email
            })
        } else {
            setError(true)
        }

    }
    return (
        <Container>
            <Divider/>
            <Wrapper>
                <Text varient="Subheading2" color="White" textAlign="center">Get notified when we launch at your school! 🔔</Text>
                <Background>
                    {!error && !success &&
                    <Fade duration={1500}>
                        <FormContainer>
                            <Form type="text" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <Submit onClick={validateEmail}>Submit</Submit>
                        </FormContainer>
                    </Fade>

                    }
                    {
                        success &&
                        <Fade duration={1500}>
                        <SucessAlert>
                            <Text fontSize={1} fontFamily="Poppins" fontWeight={300} textAlign="center" color="Green">
                                Thanks for subscribing! Keep an eye out for your confirmation email.
                        
                            </Text>
                        </SucessAlert>
                        </Fade>
                    }
                    {
                        error &&
                     <Fade duration={1500}>
                        <ErrorAlert>
                            <Text fontSize={1} fontFamily="Poppins" fontWeight={300} textAlign="center" color="Red">
                                Oh no! Looks like this email is invalid. Please try again.
                            </Text>
                            <div onClick={() => setError(false)}>
                                <NextButton />
                            </div>
                        </ErrorAlert>
                     </Fade>
                      
                      
                    }
                    </Background>
                
                <Text fontSize={1} fontFamily="Poppins" fontWeight={300} textAlign="center" color="White">
                By submitting your email you agree to receive email messages from Mighty. Don’t worry, we won’t crowd your inbox 😊
                </Text>
            </Wrapper>
        </Container>
    )
}
