import styled from "styled-components";
import Theme from "../../Theme";
import { ColorProps, TextProps } from "../../Types";



interface Props extends ColorProps, TextProps {
    varient?: 'Header1' | 'Header2' | 'Header3' | 'Body1' | 'Subheading1' |
    'Subheading2' | 'Caption1' | 'Caption2'
};

const Text = styled.p<Props>`
    margin-bottom: 0px;
    margin-top: 0px;

    ${({ varient }) => {
        switch (varient) {
            case 'Header1':
                return `
                    font-family: Poppins;
                    font-style: normal;
                    font-weight:700;
                    font-size: 3rem;
                    line-height: 1.18;

                    @media screen and (max-width: 1024px) {
                        font-size: 2rem;
                    }

                    @media screen and (max-width:768px){
                        font-size: 1.875rem;
                    }
                `
            case 'Header2':
                return `
                font-family: Poppins;
                font-style: normal;
                font-weight:700;
                font-size: 2.6875rem;
                line-height: 1.18;
                @media screen and (max-width:768px){
                    font-size:1.875rem;
                }
            `
            case 'Header3':
                return `
                font-family: Poppins;
                font-style: normal;
                font-weight:700;
                font-size: 1.25rem;
                line-height: 1.18;
            `

            case 'Subheading1':
                return `
                font-family: Inter;
                font-style: normal;
                font-weight:600;
                font-size: 1.5rem;
                line-height: 1.235;

                @media screen and (max-width:768px){
                    font-size: 1.125rem;
                }
            `
            case 'Subheading2':
                return `
                    font-family:Poppins;
                    font-style: normal;
                    font-weight:600;
                    font-size: 1.5rem;
                    line-height: 1.495;

                    @media screen and (max-width:768px){
                        font-size:1rem;
                    }
                `
            case 'Body1':
                return `
                    font-family: Inter;
                    font-style: normal;
                    font-weight:500;
                    font-size: 1.25rem;
                    line-height: 1.48;

                    @media screen and (max-width: 1024px) {
                        font-size: 1.125rem;
                    }

                    @media screen and (max-width:768px){
                        font-size:1rem;
                    }
                `
            case 'Caption1':
                return `
                    font-family:Poppins;
                    font-style:normal;
                    font-weight:600;
                    font-size:1.5rem;
                    line-height:1.695;
                    @media screen and (max-width:768px){
                        font-size:1.1875rem;
                    }
                `
            case 'Caption2':
                return `
                    font-family:Inter;
                    font-style:normal;
                    font-weight:600;
                    font-size:1.75rem;
                    line-height:1.18;

                    @media screen and (max-width:768px){
                        font-size: 1.125rem;
                    }
                `
        }


    }}
${({ fontSize }) => (fontSize ? `font-size:${fontSize}rem;` : '')}
${({ fontFamily }) => (fontFamily ? `font-family:${fontFamily};` : '')}
${({ fontWeight }) => (fontWeight ? `font-weight:${fontWeight};` : '')}
${({ textAlign }) => (textAlign ? `text-align:${textAlign};` : '')}
${({ color }) => !!color ? `color: ${Theme.palette[color]};` : ""}
${({ lineHeight }) => !!lineHeight ? `line-height: ${lineHeight};` : ""}
`

export default Text