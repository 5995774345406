import React, { ReactNode } from 'react'
import styled from 'styled-components'
import theme from '../../../Theme'
import Line from '../../../components/icons/Line'
import Box from '../../../components/core/Box'
import Text from '../../../components/core/Text'
const IconContainer = styled.div<{ iconPosition?: "topRightCorner" | "bottomRightCorner" }>`
    position:absolute;
    ${({ iconPosition }) => {
        switch (iconPosition) {
            case "topRightCorner":
                return `
                    top:-20px;
                    right:-30px;
                `
            case "bottomRightCorner":
                return `
                bottom:-20px;
                right:-20px;
                `
        }
    }}
`
type Props = {
    title: string
    subheader: string
    body?: string
    icon?: ReactNode
    iconPosition?: "topRightCorner" | "bottomRightCorner"
}
export default function Card({ title, subheader, body, icon, iconPosition }: Props) {
    return (
        <Box
            style={{ width: "75%", margin: "auto", marginTop: "20px", marginBottom: "20px", position: "relative" }}
            pt={13} pb={13} pl={14} pr={14}
            borderRadius="20px" boxShadow="0px 4px 32px rgba(188, 187, 187, 0.25)"
            backgroundColor="White"
        >
            <Box display="flex" flexDirection="row">
                <Text varient="Header3" color="Brand"> {title} </Text>
                <Box ml={3}>
                    <Line />
                </Box>
            </Box>

            <Box mt={6} mb={6}>
                <Text
                    varient="Subheading2"
                    color="Ink"
                >
                    {subheader}
                </Text>
            </Box>
            {
                !!body &&
                <Text
                    varient="Body1"
                    color="Ink"
                    style={{ width: "90%" }}
                >
                    {body}

                </Text>
            }

            <IconContainer iconPosition={iconPosition}>
                {icon}
            </IconContainer>


        </Box>
    )
}
