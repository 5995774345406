import React from 'react'
import { Carousel as BsCarousel, Container } from 'react-bootstrap'
import Card from './CauroselCard'
import BackArrow from '../../../components/icons/BackArrow'
import Flower from '../../../components/icons/Flower'
import Heart from '../../../components/icons/Heart'
import NextArrow from '../../../components/icons/NextArrow'
import Star from '../../../components/icons/Star'
export default function Carousel() {
    const mobile = window.innerWidth <= 1024;

    return (
        <Container fluid id="About" style={{ maxWidth: 1200, margin: "auto" }}>

            <BsCarousel interval={60 * 60 * 1000}
                indicators={false}
                nextIcon={<NextArrow />}
                prevIcon={<BackArrow />}
            >
                <BsCarousel.Item>
                    <Card
                        title="ABOUT US"
                        subheader="Mighty was created by a team of new grads and seasoned designers, educators, developers, writers, and media makers."
                        body="We developed Mighty inside Sandbox Labs, the tech arm of Sandbox Inc., a Toronto-based media company that works with local and global progress organizations that aim to positively impact people’s lives, including colleges and universities."
                        icon={mobile ? null : <Star />}
                        iconPosition="topRightCorner"
                    />
                </BsCarousel.Item>
                <BsCarousel.Item>
                    <Card
                        title="WHY NOW?"
                        subheader="COVID-19 has kept you apart from your classmates, it’s true, and we think the impact on remote learning and in-person social interaction will forever be changed."
                        body="But using your time at school to meet others, exchange ideas, and build a network has always been important. It can be hard to do, especially if you’re shy, if you’re the first of your family to go to university, and for so many other reasons, nevermind a pandemic. We’re bringing together what we know about human connection to give you a place to connect with others while you’re in school."
                        icon={mobile ? null : <Flower />}
                        iconPosition="bottomRightCorner"
                    />

                </BsCarousel.Item>
                <BsCarousel.Item>
                    <Card
                        title="WHY US"
                        subheader="Sandbox Inc. exists because our CEO took a chance on a random elective course at university, where he happened to meet his lifelong friends and collaborators."
                        body="A connection between undergrads who may not have met otherwise can be a powerful thing. We’ve taken that idea of this chance IRL connection, and evolved it into an app. That’s what Mighty’s here for."
                        icon={mobile ? null : <Heart />}
                        iconPosition="topRightCorner"
                    />

                </BsCarousel.Item>
            </BsCarousel>
        </Container>


    )
}
