import React from "react"

function NextArrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={27}
            height={27}
            viewBox="0 0 27 27"
            fill="none"
        >
            <path
                d="M20.962 15.278H0v-3.556h20.962l-8.616-8.695a1.777 1.777 0 010-2.507 1.756 1.756 0 012.495 0l11.642 11.735c.69.694.69 1.814 0 2.507L14.823 26.48a1.756 1.756 0 01-2.495 0 1.799 1.799 0 010-2.525l8.634-8.677z"
                fill="#202020"
            />
        </svg>
    )
}

export default NextArrow