import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import MightyIcon from '../icons/Mighty'
import { HashLink } from 'react-router-hash-link';
import theme from '../../Theme';
import { useLocation } from 'react-router-dom';
import { isIOS, isMacOs } from 'react-device-detect'
import mixpanel from 'mixpanel-browser'

const Nav = styled.div<{ homePageTop: boolean }>`
    height: 90px;
    padding: 0 30px;

    position:fixed;
    top:0;
    left:0;
    right:0;
    transition: all 0.2s ease-in-out;
    background-color:${({ homePageTop }) => homePageTop ? "transparent" : theme.palette.White};
    z-index: 2;
    display: flex;
    justify-content: center;
    box-shadow: ${({ homePageTop }) => homePageTop ? '' : "0px 4px 12px rgba(0, 0, 0, 0.25)"};

    @media screen and (max-width: 768px) {
        padding: 0 34px;
    }

    .content {
        max-width: 1140px;
        width: 100%;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
    }
`

const Link = styled(HashLink) <{ homePageTop?: boolean }>`
    font-family:Inter;
    font-weight:600;
    color:${({ homePageTop }) => homePageTop ? theme.palette.White : theme.palette.Brand};
    font-size:1rem;
    line-height:1.19;
    margin-right:40px;
    :hover{
        color:${({ homePageTop }) => homePageTop ? theme.palette.White : theme.palette.Brand};
        text-decoration:underline solid ${({ homePageTop }) => homePageTop ? theme.palette.White : theme.palette.Brand};
    }
`
const DownloadButton = styled.div<{ homePageTop: boolean }>`
    padding: 0.5rem 1rem;
    margin-right:40px;
    border-radius:4px;
    background-color:${({ homePageTop }) => homePageTop ? theme.palette.White : theme.palette.Brand};
    color:${({ homePageTop }) => homePageTop ? theme.palette.Brand : theme.palette.White};
    text-align:center;
    font-family:Inter;
    font-weight:600;
    font-size:1rem;
    line-height:1.19;
`
const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

let downloadLink: string
if (isMacOs || isIOS) downloadLink = "https://apps.apple.com/ca/app/mighty/id1551423127"
else downloadLink = "https://play.google.com/store/apps/details?id=com.sandboxlabs.Mighty"


export default function Desktop() {
    const [scrolledTop, setScrolledTop] = useState(true)
    const { pathname } = useLocation()
    const homePageTop = pathname === "/" && scrolledTop

    useEffect(() => {
        const breakPoint = 40
        const onScroll = () => {
            const st = window.pageYOffset || document.documentElement.scrollTop
            setScrolledTop(st < breakPoint)
        }
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return (
        <Nav homePageTop={homePageTop}>
            <div className='content'>
                <Link smooth={true} to="/#">
                    <MightyIcon height={27} width={148} color={homePageTop ? theme.palette.White : theme.palette.Brand} />
                </Link>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Link homePageTop={homePageTop} smooth={true} scroll={scrollWithOffset} to="/#Mighty"> Why Mighty?</Link>
                    <Link homePageTop={homePageTop} smooth={true} scroll={scrollWithOffset} to="/#Connections">Smart Connections</Link>
                    <Link homePageTop={homePageTop} smooth={true} scroll={scrollWithOffset} to="/#Progress">Academic Progress</Link>
                    <Link homePageTop={homePageTop} smooth={true} scroll={scrollWithOffset} to="/#About">About Us</Link>
                    <a href={downloadLink} target="_blank" rel="noreferrer"
                        onClick={() => mixpanel.track(isMacOs || isIOS ? "Download-IOS" : "Download-Android", { location: "nav" })}
                    >
                        <DownloadButton homePageTop={homePageTop}>
                            Download
                        </DownloadButton>
                    </a>
                </div>
            </div>
        </Nav>
    )
}
