import React from "react"

function BackArrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={27}
            height={27}
            viewBox="0 0 27 27"
            fill="none"
        >
            <path
                d="M6.038 11.722H27v3.556H6.038l8.616 8.695c.69.693.69 1.814 0 2.507a1.756 1.756 0 01-2.495 0L.518 14.745a1.777 1.777 0 010-2.507L12.177.52a1.756 1.756 0 012.495 0c.69.694.69 1.832 0 2.525l-8.634 8.677z"
                fill="#202020"
            />
        </svg>
    )
}

export default BackArrow