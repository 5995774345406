import styled from "styled-components";
import {
      SpacingProps,
      DisplayProps,
      FlexProps,
      DimensionProps,
      PositionProps,
      ColorProps,
      ShapeProps
} from "../../Types";
import Theme from "../../Theme";

interface BoxProps
      extends SpacingProps,
      DisplayProps,
      FlexProps,
      DimensionProps,
      PositionProps,
      ShapeProps,
      ColorProps { }

const Box = styled.div<BoxProps>`
  ${({ p }: BoxProps) => (p ? `padding: ${Theme.spacing(p)}px;` : "")}
  ${({ pt }: BoxProps) => (pt ? `padding-top: ${Theme.spacing(pt)}px;` : "")}
  ${({ pb }: BoxProps) => (pb ? `padding-bottom: ${Theme.spacing(pb)}px;` : "")}
  ${({ pr }: BoxProps) => (pr ? `padding-right: ${Theme.spacing(pr)}px;` : "")}
  ${({ pl }: BoxProps) => (pl ? `padding-left: ${Theme.spacing(pl)}px;` : "")}

  ${({ m }: BoxProps) => (m ? `margin: ${Theme.spacing(m)}px;` : "")}
  ${({ mt }: BoxProps) => (mt ? `margin-top: ${Theme.spacing(mt)}px;` : "")}
  ${({ mb }: BoxProps) => (mb ? `margin-bottom: ${Theme.spacing(mb)}px;` : "")}
  ${({ mr }: BoxProps) => (mr ? `margin-right: ${Theme.spacing(mr)}px;` : "")}
  ${({ ml }: BoxProps) => (ml ? `margin-left: ${Theme.spacing(ml)}px;` : "")}

  ${({ display }: BoxProps) => (!!display ? `display: ${display};` : "")}
  ${({ flex }: BoxProps) => (!!flex ? `flex: ${flex};` : "")}
  ${({ flexDirection }: BoxProps) =>
            !!flexDirection ? `flex-direction: ${flexDirection};` : ""}
  ${({ flexGrow }: BoxProps) => (!!flexGrow ? `flex-grow: ${flexGrow};` : "")}
  ${({ flexWrap }: BoxProps) => (!!flexWrap ? `flex-wrap: ${flexWrap};` : "")}
  ${({ justifyContent }: BoxProps) =>
            !!justifyContent ? `justify-content: ${justifyContent};` : ""}
  ${({ alignItems }: BoxProps) =>
            !!alignItems ? `align-items: ${alignItems};` : ""}

  ${({ order }: BoxProps) => order ? `order:${order};` : ""}
  ${({ height }: BoxProps) => (!!height ? `height: ${height};` : "")}
  ${({ minHeight }: BoxProps) => !!minHeight ? `min-height: ${minHeight};` : ""}
  ${({ maxHeight }: BoxProps) => !!maxHeight ? `max-height: ${maxHeight};` : ""}
  ${({ width }: BoxProps) => (!!width ? `width: ${width};` : "")}
  ${({ minWidth }: BoxProps) => (!!minWidth ? `min-width: ${minWidth};` : "")}
  ${({ maxWidth }: BoxProps) => (!!maxWidth ? `max-width: ${maxWidth};` : "")}
  
  ${({ position }: BoxProps) => (!!position ? `position: ${position};` : "")}
  ${({ right }: BoxProps) => (!!right ? `right: ${right};` : "")}
  ${({ left }: BoxProps) => (!!left ? `left: ${left};` : "")}
  ${({ bottom }: BoxProps) => (!!bottom ? `bottom: ${bottom};` : "")}
  ${({ top }: BoxProps) => (!!top ? `top: ${top};` : "")}
  ${({ zIndex }: BoxProps) => (!!zIndex ? `z-index: ${zIndex};` : "")}
  ${({ overflow }: BoxProps) => (overflow ? `overflow: ${overflow};` : "")}
  ${({ color }: BoxProps) => (!!color ? `color: ${Theme.palette[color]};` : "")}
  ${({ borderRadius }: BoxProps) => (borderRadius ? `border-radius:${borderRadius};` : "")}
  ${({ boxShadow }: BoxProps) => (boxShadow ? `box-shadow:${boxShadow};` : "")}
  ${({ backgroundColor }: BoxProps) =>
            !!backgroundColor
                  ? `background-color: ${Theme.palette[backgroundColor]};`
                  : ""}
  ${({ backgroundImage }: BoxProps) =>
            !!backgroundImage
                  ? `background-image: ${backgroundImage};`
                  : ""}

`;

export default Box;
