import React from "react"
type Props = {
    color?: string
}
function Hamburger({ color }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={22}
            viewBox="0 0 32 22"
            fill="none"
        >
            <path
                d="M0 21a1 1 0 001 1h30a1 1 0 001-1v-1.667a1 1 0 00-1-1H1a1 1 0 00-1 1V21zm0-9.167a1 1 0 001 1h30a1 1 0 001-1v-1.666a1 1 0 00-1-1H1a1 1 0 00-1 1v1.666zM1 0a1 1 0 00-1 1v1.667a1 1 0 001 1h30a1 1 0 001-1V1a1 1 0 00-1-1H1z"
                fill={color ? color : "#fff"}
            />
        </svg>
    )
}

export default Hamburger