import React from 'react'

export default function NextButton() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#FF5454" />
            <path d="M16.9765 12.6818C17.1263 12.8256 17.2144 13.0241 17.2144 13.2354C17.2144 13.4455 17.1275 13.6448 16.9762 13.7893L11.6422 18.8962C11.4865 19.0453 11.2849 19.1177 11.0871 19.1177C10.8884 19.1177 10.6862 19.0446 10.5305 18.8947C10.2144 18.5904 10.2153 18.0919 10.532 17.7887L15.2878 13.2352L10.5323 8.682C10.2156 8.37874 10.2146 7.88003 10.5307 7.57574C10.839 7.27934 11.3333 7.27879 11.6421 7.5741L16.9765 12.6818Z" fill="white" />
        </svg>
    )
}
