const AppleStore = ({ size }: { size?: number }) => <svg height={size || 70} viewBox="0 0 462 145" fill="none">
    <path d="M447.562 143.5H14.4375C7.3314 143.5 1.5 137.653 1.5 130.5V14.5C1.5 7.34731 7.3314 1.5 14.4375 1.5H447.562C454.669 1.5 460.5 7.34731 460.5 14.5V130.5C460.5 137.653 454.669 143.5 447.562 143.5Z" fill="black" stroke="#A6A6A6" strokeWidth="3" />
    <path d="M97.4538 71.4658C97.4538 60.2768 106.838 54.8627 107.199 54.5018C101.785 46.9221 93.8444 45.8393 90.9569 45.4783C84.0991 44.7565 77.2413 49.4486 73.9928 49.4486C70.3835 49.4486 64.9694 45.4783 59.1944 45.4783C51.6147 45.4783 44.7569 49.8096 40.7866 56.6674C32.846 70.383 38.621 90.5955 46.2007 101.785C50.171 107.199 54.5022 113.335 60.2772 112.974C66.0522 112.613 68.2179 109.364 75.0757 109.364C81.9335 109.364 83.7382 112.974 89.8741 112.974C96.01 112.974 99.9804 107.56 103.59 102.146C107.921 96.0096 109.726 89.8736 109.726 89.5127C109.365 89.1518 97.4538 84.8205 97.4538 71.4658Z" fill="white" />
    <path d="M86.2648 38.2598C89.1523 34.2895 91.318 29.2363 90.957 23.8223C86.6258 24.1832 80.8508 26.7098 77.9633 30.6801C75.0758 33.9285 72.5492 39.3426 73.2711 44.3957C77.9633 44.7566 83.0164 41.8691 86.2648 38.2598Z" fill="white" />
    <path d="M179.747 112.252H171.806L167.475 98.5362H152.316L148.345 112.252H140.766L155.925 65.6909H165.309L179.747 112.252ZM166.031 92.7612L162.061 80.4894C161.7 79.4065 160.978 76.1581 159.534 71.8269C159.173 73.6315 158.452 76.88 157.369 80.4894L153.398 92.7612H166.031Z" fill="white" />
    <path d="M218.367 95.2878C218.367 101.063 216.923 105.394 213.675 109.003C210.788 111.891 207.539 113.335 203.208 113.335C198.877 113.335 195.628 111.891 193.823 108.642V126.328H186.244V90.2347C186.244 86.6253 186.244 83.0159 185.883 79.0456H192.38L192.741 84.4597C195.267 80.4894 198.877 78.3237 203.93 78.3237C207.9 78.3237 211.148 79.7675 213.675 83.0159C216.923 85.9034 218.367 89.8737 218.367 95.2878ZM210.788 95.2878C210.788 92.0394 210.066 89.1519 208.622 87.3472C207.178 85.1815 205.013 84.0987 202.125 84.0987C200.32 84.0987 198.516 84.8206 197.072 85.9034C195.628 86.9862 194.545 88.7909 194.184 90.5956C193.823 91.6784 193.823 92.4003 193.823 92.7612V98.5362C193.823 101.063 194.545 102.867 195.989 104.672C197.433 106.477 199.598 107.199 201.764 107.199C204.652 107.199 206.817 106.116 208.261 103.95C210.066 101.785 210.788 98.8972 210.788 95.2878Z" fill="white" />
    <path d="M256.988 95.2878C256.988 101.063 255.545 105.394 252.296 109.003C249.409 111.891 246.16 113.335 241.829 113.335C237.498 113.335 234.249 111.891 232.445 108.642V126.328H224.865V90.2347C224.865 86.6253 224.865 83.0159 224.504 79.0456H231.001L231.362 84.4597C233.888 80.4894 237.498 78.3237 242.551 78.3237C246.521 78.3237 249.77 79.7675 252.296 83.0159C255.545 85.9034 256.988 89.8737 256.988 95.2878ZM249.409 95.2878C249.409 92.0394 248.687 89.1519 247.243 87.3472C245.799 85.1815 243.634 84.0987 240.746 84.0987C238.941 84.0987 237.137 84.8206 235.693 85.9034C234.249 86.9862 233.166 88.7909 232.805 90.5956C232.445 91.6784 232.445 92.4003 232.445 92.7612V98.5362C232.445 101.063 233.166 102.867 234.61 104.672C236.054 106.477 238.22 107.199 240.385 107.199C243.273 107.199 245.438 106.116 246.882 103.95C248.687 101.785 249.409 98.8972 249.409 95.2878Z" fill="white" />
    <path d="M300.299 99.2582C300.299 103.228 298.855 106.477 296.329 109.003C293.441 111.891 289.11 112.974 283.696 112.974C278.643 112.974 274.673 111.891 271.785 110.086L273.59 103.95C276.838 105.755 280.448 106.838 284.418 106.838C287.305 106.838 289.471 106.116 290.915 105.033C292.359 103.589 293.08 102.146 293.08 99.9801C293.08 98.1754 292.359 96.3707 291.276 95.2879C289.832 93.8441 287.666 92.7613 284.779 91.6785C276.477 88.791 272.507 84.0988 272.507 78.3238C272.507 74.3535 273.951 71.466 276.838 68.9395C279.726 66.4129 283.335 65.3301 288.388 65.3301C292.72 65.3301 295.968 66.052 298.855 67.4957L297.051 73.6316C294.524 72.1879 291.637 71.466 288.027 71.466C285.501 71.466 283.335 72.1879 281.891 73.2707C280.809 74.3535 280.087 75.7973 280.087 77.6019C280.087 79.4066 280.809 80.8504 282.252 82.2941C283.335 83.3769 285.862 84.4598 289.11 85.9035C293.08 87.3473 295.968 89.5129 298.134 91.6785C299.216 93.4832 300.299 96.0097 300.299 99.2582Z" fill="white" />
    <path d="M324.843 84.4594H316.541V100.702C316.541 104.672 317.985 106.838 320.873 106.838C322.316 106.838 323.399 106.838 324.121 106.477L324.482 112.252C323.038 112.973 321.234 112.973 318.707 112.973C315.82 112.973 313.293 112.252 311.849 110.447C310.045 108.642 309.323 105.755 309.323 101.423V84.4594H304.27V78.6844H309.323V72.5484L316.541 70.3828V78.6844H324.843V84.4594Z" fill="white" />
    <path d="M362.021 95.2879C362.021 100.341 360.577 104.672 357.69 107.921C354.441 111.169 350.471 112.974 345.418 112.974C340.365 112.974 336.395 111.169 333.507 107.921C330.62 104.672 329.176 100.702 329.176 95.6488C329.176 90.5957 330.62 86.2644 333.868 83.016C336.755 79.7676 341.087 77.9629 346.14 77.9629C351.193 77.9629 355.163 79.7676 358.051 83.016C360.577 86.2644 362.021 90.2348 362.021 95.2879ZM354.08 95.6488C354.08 92.4004 353.359 89.8738 351.915 87.7082C350.471 85.1816 347.945 83.7379 345.057 83.7379C342.17 83.7379 339.643 85.1816 338.199 87.7082C336.755 89.8738 336.034 92.7613 336.034 95.6488C336.034 98.8972 336.755 101.424 338.199 103.589C339.643 106.116 342.17 107.56 345.057 107.56C347.945 107.56 350.11 106.116 351.915 103.589C353.359 101.424 354.08 98.5363 354.08 95.6488Z" fill="white" />
    <path d="M386.204 85.1812C385.482 85.1812 384.76 84.8203 383.677 84.8203C381.151 84.8203 378.985 85.9031 377.541 87.7078C376.458 89.5125 375.737 91.6781 375.737 94.2047V111.891H368.157V88.7906C368.157 84.8203 368.157 81.5719 367.796 78.3234H374.293L374.654 84.8203H375.015C375.737 82.6547 377.18 80.85 378.624 79.4062C380.429 78.3234 382.233 77.6016 384.038 77.6016C384.76 77.6016 385.482 77.6016 385.843 77.6016V85.1812H386.204Z" fill="white" />
    <path d="M419.41 93.8441C419.41 95.2879 419.41 96.3707 419.049 97.0926H396.671C396.671 100.341 397.754 102.868 399.919 104.672C401.724 106.116 404.251 107.199 407.138 107.199C410.386 107.199 413.635 106.838 416.161 105.755L417.244 110.808C413.996 112.252 410.386 112.974 406.055 112.974C401.002 112.974 396.671 111.53 393.783 108.282C390.896 105.394 389.452 101.063 389.452 96.0097C389.452 90.9566 390.896 86.6254 393.422 83.3769C396.31 79.7676 400.28 77.9629 405.333 77.9629C410.026 77.9629 413.996 79.7676 416.161 83.3769C418.688 86.2644 419.41 89.8738 419.41 93.8441ZM412.552 92.0394C412.552 89.8738 412.191 88.0691 411.108 86.2645C409.665 84.0988 407.86 83.016 405.333 83.016C402.807 83.016 401.002 84.0988 399.558 85.9035C398.476 87.3473 397.754 89.5129 397.393 91.6785H412.552V92.0394Z" fill="white" />
    <path d="M163.866 37.1763C163.866 41.1466 162.784 44.395 160.257 46.5606C158.091 48.3653 154.843 49.4481 150.512 49.4481C148.346 49.4481 146.541 49.4481 145.098 49.0872V26.7091C146.902 26.3481 149.068 26.3481 151.595 26.3481C155.565 26.3481 158.452 27.07 160.618 28.8747C162.423 30.6794 163.866 33.5669 163.866 37.1763ZM159.896 37.1763C159.896 34.6497 159.174 32.4841 157.73 31.0403C156.287 29.5966 154.121 28.8747 151.595 28.8747C150.512 28.8747 149.429 28.8747 148.707 29.2356V46.1997C149.068 46.1997 150.151 46.1997 151.234 46.1997C154.121 46.1997 156.287 45.4778 157.73 44.0341C159.174 42.5903 159.896 40.0638 159.896 37.1763Z" fill="white" />
    <path d="M184.078 40.7855C184.078 43.3121 183.356 45.4777 181.913 46.9215C180.469 48.7262 178.303 49.4481 175.777 49.4481C173.25 49.4481 171.445 48.7262 170.002 46.9215C168.558 45.4777 167.836 43.3121 167.836 40.7855C167.836 38.259 168.558 36.0934 170.002 34.6496C171.445 32.8449 173.611 32.123 176.138 32.123C178.664 32.123 180.469 32.8449 181.913 34.6496C183.356 36.0934 184.078 38.259 184.078 40.7855ZM180.469 40.7855C180.469 39.3418 180.108 37.8981 179.386 36.8152C178.664 35.3715 177.581 35.0106 176.138 35.0106C174.694 35.0106 173.611 35.7324 172.889 36.8152C172.167 37.8981 171.806 39.3418 171.806 40.7855C171.806 42.2293 172.167 43.673 172.889 44.7559C173.611 46.1996 174.694 46.5605 176.138 46.5605C177.581 46.5605 178.664 45.8387 179.386 44.3949C180.108 43.673 180.469 42.2293 180.469 40.7855Z" fill="white" />
    <path d="M211.87 32.4844L206.817 49.0875H203.569L201.403 41.8688C200.681 40.0641 200.32 38.2594 199.959 36.4547C199.598 38.2594 199.237 40.0641 198.516 41.8688L196.35 49.0875H193.102L187.688 32.4844H191.297L193.102 40.425C193.462 42.2297 193.823 44.0344 194.184 45.8391C194.545 44.3953 194.906 42.5906 195.628 40.425L197.794 32.4844H200.681L202.847 40.0641C203.569 41.8688 203.93 43.6734 204.291 45.4781C204.652 43.6734 205.012 41.8688 205.373 40.0641L207.539 32.4844H211.87Z" fill="white" />
    <path d="M230.639 49.0872H227.03V39.7028C227.03 36.8153 225.947 35.3716 223.781 35.3716C222.698 35.3716 221.977 35.7325 221.255 36.4544C220.533 37.1763 220.172 38.2591 220.172 39.3419V49.0872H216.562V37.1763C216.562 35.7325 216.562 34.2888 216.562 32.4841H219.811V35.0106C220.172 34.2888 220.894 33.5669 221.616 32.845C222.698 32.1231 223.781 31.7622 224.864 31.7622C226.308 31.7622 227.752 32.1231 228.834 33.206C230.278 34.2888 230.639 36.0935 230.639 38.62V49.0872Z" fill="white" />
    <path d="M240.746 49.0871H237.137V24.9043H240.746V49.0871Z" fill="white" />
    <path d="M262.402 40.7855C262.402 43.3121 261.68 45.4777 260.237 46.9215C258.793 48.7262 256.627 49.4481 254.101 49.4481C251.574 49.4481 249.77 48.7262 248.326 46.9215C246.882 45.4777 246.16 43.3121 246.16 40.7855C246.16 38.259 246.882 36.0934 248.326 34.6496C249.77 33.2059 251.935 32.123 254.462 32.123C256.988 32.123 258.793 32.8449 260.237 34.6496C261.68 36.0934 262.402 38.259 262.402 40.7855ZM258.793 40.7855C258.793 39.3418 258.432 37.8981 257.71 36.8152C256.988 35.3715 255.905 35.0106 254.462 35.0106C253.018 35.0106 251.935 35.7324 251.213 36.8152C250.491 37.8981 250.13 39.3418 250.13 40.7855C250.13 42.2293 250.491 43.673 251.213 44.7559C251.935 46.1996 253.018 46.5605 254.462 46.5605C255.905 46.5605 256.988 45.8387 257.71 44.3949C258.432 43.673 258.793 42.2293 258.793 40.7855Z" fill="white" />
    <path d="M280.088 49.0871H276.84L276.479 47.2824C275.396 48.7262 273.591 49.4481 271.787 49.4481C270.343 49.4481 268.899 49.0871 268.177 48.0043C267.455 46.9215 266.733 45.8387 266.733 44.7559C266.733 42.5902 267.455 41.1465 269.26 40.0637C271.065 38.9809 273.23 38.6199 276.479 38.6199V38.259C276.479 36.0934 275.396 35.0106 273.23 35.0106C271.426 35.0106 270.343 35.3715 268.899 36.0934L268.177 33.5668C269.621 32.484 271.426 32.123 273.952 32.123C278.283 32.123 280.449 34.2887 280.449 38.9809V45.1168C280.088 46.9215 280.088 48.0043 280.088 49.0871ZM276.479 43.3121V40.7855C272.508 40.7855 270.343 41.8684 270.343 44.034C270.343 44.7559 270.704 45.4777 271.065 45.8387C271.426 46.1996 272.147 46.5605 272.869 46.5605C273.591 46.5605 274.313 46.1996 275.035 45.8387C275.757 45.4777 276.118 44.7559 276.479 44.034C276.479 44.034 276.479 43.673 276.479 43.3121Z" fill="white" />
    <path d="M301.021 49.0875H297.773V46.5609C296.69 48.7266 294.885 49.4484 292.359 49.4484C290.193 49.4484 288.749 48.7266 287.306 47.2828C285.862 45.8391 285.501 43.6734 285.501 41.1469C285.501 38.6203 286.223 36.4547 287.667 34.65C289.11 33.2063 290.915 32.4844 292.72 32.4844C294.885 32.4844 296.329 33.2063 297.412 34.65V25.2656H301.021V44.7563C301.021 46.2 301.021 47.6438 301.021 49.0875ZM297.412 42.2297V39.3422C297.412 38.9813 297.412 38.6203 297.412 38.2594C297.051 37.5375 296.69 36.8156 295.968 36.0938C295.246 35.3719 294.524 35.3719 293.442 35.3719C291.998 35.3719 290.915 36.0938 290.193 37.1766C289.471 38.2594 289.11 39.7031 289.11 41.5078C289.11 43.3125 289.471 44.3953 290.193 45.4781C290.915 46.5609 291.998 47.2828 293.442 47.2828C294.524 47.2828 295.607 46.9219 296.329 45.8391C297.051 44.3953 297.412 43.3125 297.412 42.2297Z" fill="white" />
    <path d="M332.423 40.7855C332.423 43.3121 331.701 45.4777 330.257 46.9215C328.813 48.7262 326.648 49.4481 324.121 49.4481C321.595 49.4481 319.79 48.7262 318.346 46.9215C316.903 45.4777 316.181 43.3121 316.181 40.7855C316.181 38.259 316.903 36.0934 318.346 34.6496C319.79 32.8449 321.956 32.123 324.482 32.123C327.009 32.123 328.813 32.8449 330.257 34.6496C331.701 36.0934 332.423 38.259 332.423 40.7855ZM328.453 40.7855C328.453 39.3418 328.092 37.8981 327.37 36.8152C326.648 35.3715 325.565 35.0106 324.121 35.0106C322.678 35.0106 321.595 35.7324 320.873 36.8152C320.151 37.8981 319.79 39.3418 319.79 40.7855C319.79 42.2293 320.151 43.673 320.873 44.7559C321.595 46.1996 322.678 46.5605 324.121 46.5605C325.565 46.5605 326.648 45.8387 327.37 44.3949C328.092 43.673 328.453 42.2293 328.453 40.7855Z" fill="white" />
    <path d="M352.276 49.0872H348.666V39.7028C348.666 36.8153 347.584 35.3716 345.418 35.3716C344.335 35.3716 343.613 35.7325 342.891 36.4544C342.17 37.1763 341.809 38.2591 341.809 39.3419V49.0872H338.199V37.1763C338.199 35.7325 338.199 34.2888 338.199 32.4841H341.448V35.0106C341.809 34.2888 342.53 33.5669 343.252 32.845C344.335 32.1231 345.418 31.7622 346.501 31.7622C347.945 31.7622 349.388 32.1231 350.471 33.206C351.915 34.2888 352.276 36.0935 352.276 38.62V49.0872Z" fill="white" />
    <path d="M376.82 35.3715H372.849V43.3121C372.849 45.4777 373.571 46.1996 375.015 46.1996C375.737 46.1996 376.098 46.1996 376.82 46.1996V49.0871C376.098 49.448 375.015 49.4481 373.932 49.4481C372.488 49.4481 371.405 49.0871 370.684 48.0043C369.962 47.2824 369.601 45.4777 369.601 43.6731V35.3715H367.074V32.484H369.601V29.5965L373.21 28.5137V32.484H377.18V35.3715H376.82Z" fill="white" />
    <path d="M396.31 49.0871H392.701V39.7027C392.701 36.8152 391.618 35.3715 389.452 35.3715C387.647 35.3715 386.565 36.0934 385.843 37.898C385.843 38.259 385.843 38.6199 385.843 39.3418V49.0871H382.233V24.9043H385.843V35.0106C386.926 33.2059 388.73 32.123 390.896 32.123C392.34 32.123 393.783 32.484 394.505 33.5668C395.588 34.6496 396.31 36.8152 396.31 38.9809V49.0871Z" fill="white" />
    <path d="M416.16 40.0641C416.16 40.7859 416.16 41.1469 416.16 41.8688H405.332C405.332 43.6734 406.054 44.7563 406.776 45.4781C407.859 46.2 408.941 46.5609 410.385 46.5609C412.19 46.5609 413.634 46.2 414.716 45.8391L415.438 48.3656C413.995 49.0875 412.19 49.4484 410.024 49.4484C407.498 49.4484 405.332 48.7266 403.888 47.2828C402.445 45.8391 401.723 43.6734 401.723 41.1469C401.723 38.6203 402.445 36.4547 403.888 35.0109C405.332 33.2063 407.137 32.4844 409.663 32.4844C412.19 32.4844 413.995 33.2063 415.077 35.0109C415.799 36.0938 416.16 37.8984 416.16 40.0641ZM412.912 38.9812C412.912 37.8984 412.551 36.8156 412.19 36.0938C411.468 35.0109 410.746 34.65 409.302 34.65C408.22 34.65 407.137 35.0109 406.415 36.0938C405.693 36.8156 405.332 37.8984 405.332 38.9812H412.912Z" fill="white" />
</svg>

export default AppleStore