import React from 'react'
import Email from '../../components/core/Email'
import About from './About'
import Connection from './Connection'
import Download from './Download'
import Header from './Header'
import Mighty from './Mighty'
import Progress from './Progress'

export default function Home() {
    return (
        <>
            <Header />
            <Mighty />
            <Connection />
            <Progress />
            <About />
            <Download />
            <Email />
        </>
    )
}
