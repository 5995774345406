import React from 'react'
import { Accordion as BsAccordion, Card as BsCard, Col, Container, Row } from 'react-bootstrap'
import Box from '../../../components/core/Box'
import Text from '../../../components/core/Text'
import Line from '../../../components/icons/Line'
type CardProps = {
    header: string
    body1: string
    body2?: string
    eventKey: string
    id?: string
}
function Card({ header, body1, body2, eventKey, id }: CardProps) {
    return (
        <BsCard id={id} style={{ border: "0", marginBottom: "1.5rem", boxShadow: "0px 0px 20px rgba(0,0,0,0.1)", borderRadius: "1.125rem" }}>
            <BsCard.Header style={{ backgroundColor: "white", border: "0", padding: "2rem" }}>
                <BsAccordion.Toggle as={BsCard.Header} eventKey={eventKey} style={{ backgroundColor: "white", border: "0", padding: 0 }} >
                    <Box display="flex" flexDirection="row">
                        <Text varient="Header3" color="Brand"> {header} </Text>
                        <Box ml={3}>
                            <Line />
                        </Box>
                    </Box>
                </BsAccordion.Toggle>
            </BsCard.Header>
            <BsAccordion.Collapse eventKey={eventKey}>
                <BsCard.Body style={{ padding: "2rem", paddingTop: 0 }}>
                    <Text varient="Subheading2" color="Ink"> {body1}</Text>
                    {!!body2 && <Text varient="Body1" className="mt-3" color="Ink">{body2}</Text>}
                </BsCard.Body>
            </BsAccordion.Collapse>
        </BsCard>
    )
}
export default function Accordion() {
    return (
        <Container fluid="lg" className="mt-5">
            <Row className="justify-content-center">
                <Col xs={10}>

                    <BsAccordion defaultActiveKey="0" style={{ border: "0" }}>
                        <Card
                            id="About"
                            eventKey="0"
                            header="ABOUT US"
                            body1="Mighty was created by a team of new grads and seasoned designers, educators, developers, writers, and media makers."
                            body2="We developed Mighty inside Sandbox Labs, the tech arm of Sandbox Inc., a Toronto-based media company that works with local and global progress organizations that aim to positively impact people’s lives, including colleges and universities." />

                        <Card
                            eventKey="1"
                            header="WHY NOW?"
                            body1="COVID-19 has kept you apart from your classmates, it’s true, and we think the impact on remote learning and in-person social interaction will forever be changed. But using your time at school to meet others, exchange ideas, and build a network has always been important."
                            body2="It can be hard to do, especially if you’re shy, if you’re the first of your family to go to university, and for so many other reasons, nevermind a pandemic. We’re bringing together what we know about human connection to give you a place to connect with others while you’re in school—whether that means studying at home between Zoom calls, or networking again someday on campus and in the classroom. " />

                        <Card
                            eventKey="2"
                            header="WHY US"
                            body1="Sandbox Inc. exists because our CEO took a chance on a random elective course at university, where he happened to meet his lifelong friends and collaborators.  A connection between undergrads who may not  have met otherwise can be a powerful thing. That’s what Mighty’s here for. 
                        "
                        />

                    </BsAccordion>
                </Col>

            </Row>
        </Container>
    )
}
