import * as React from "react"

function Heart() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={116}
            height={118}
            viewBox="0 0 116 118"
            fill="none"
        >
            <path
                d="M58.014 6.766c8.771 0 17.528-.043 26.299.015 10.25.072 19.855 6.638 23.662 16.04 1.318 3.253 1.948 6.638 1.948 10.138 0 11.382.117 22.78-.029 34.162-.147 11.165-5.477 19.207-15.697 24.023-3.075 1.446-6.37 2.097-9.767 2.155-3.163.058-6.311.029-9.474.014-.893 0-1.538.29-2.08.984a4164.642 4164.642 0 01-12.519 15.721c-.922 1.157-2.094 1.606-3.265 1.114-.527-.217-1.04-.622-1.406-1.056a2141.407 2141.407 0 01-12.373-15.519c-.688-.882-1.45-1.272-2.592-1.243-2.767.057-5.52.028-8.288.014-7.672-.043-14.174-2.791-19.401-8.316-4.35-4.614-6.721-10.124-6.912-16.445-.146-4.556-.058-9.112-.073-13.667V33.118c0-3.977.688-7.81 2.46-11.383C12.475 13.71 18.89 8.82 27.762 7.157c1.552-.29 3.178-.376 4.774-.39 8.478-.03 16.97-.015 25.478 0 0-.015 0-.015 0 0z"
                fill="#fff"
            />
            <path
                d="M58 117.337c-2.709 0-5.3-1.302-7.102-3.558l-2.02-2.546c-3.105-3.905-6.326-7.94-9.489-11.917-.556 0-1.128.014-1.684.014-.922.014-1.889.014-2.84.014-1.538 0-2.797-.028-3.983-.072-7.82-.318-14.716-3.153-20.5-8.432C4.817 85.778 1.435 79.4.337 71.922.132 70.534.029 69.015.015 67.41 0 56.65 0 45.686 0 35.085V32.77c0-7.507 2.65-14.796 7.468-20.51C12.285 6.55 19.05 2.659 26.518 1.3 28.363.966 30.413.793 32.58.793c3.705-.015 7.41-.015 11.1-.03C49.975.75 56.491.72 62.89.72c7.82 0 14.35.03 20.56.087 6.808.072 12.811 1.736 17.819 4.946 4.95 3.168 8.962 7.883 11.934 13.986 1.494 3.052 2.387 6.538 2.665 10.356 0 .029.015.058.015.087.014.086.044.173.058.26l.044.231v38.675l-.029.159c-.044.362-.102.723-.146 1.085-.103.839-.205 1.692-.381 2.574-1.991 10.023-7.614 17.573-16.693 22.462-4.54 2.444-9.62 3.688-15.067 3.688h-.293c-.747 0-1.508-.015-2.255-.015h-4.525c-2.899 3.645-5.842 7.347-8.698 10.92l-2.81 3.529c-1.773 2.285-4.38 3.587-7.088 3.587zm-17.161-26.41c1.8 0 3.25.709 4.392 2.17 3.661 4.657 7.585 9.574 12.359 15.504.102.13.293.275.44.347.058-.029.204-.13.424-.39l2.167-2.72c3.397-4.252 6.911-8.663 10.338-13.002.996-1.258 2.328-1.91 3.968-1.91h.044c1.596.015 3.31.03 5.022.03 1.611 0 3.046-.015 4.393-.03 3.222-.057 6.165-.693 8.771-1.923 4.686-2.213 8.259-5.163 10.631-8.764 2.372-3.63 3.617-8.042 3.675-13.133.103-7.868.073-15.88.059-23.618-.015-3.443-.015-7-.015-10.5 0-3.226-.6-6.335-1.772-9.257-1.698-4.18-4.641-7.796-8.536-10.428-3.895-2.647-8.361-4.064-12.886-4.093-3.016-.014-6.238-.029-10.147-.029-2.695 0-5.374 0-8.069.015-2.694 0-5.373.014-8.068.014H44.924c-4.978 0-8.786 0-12.373.029-1.743.014-3.119.116-4.32.347-4.027.752-7.54 2.256-10.44 4.455-2.885 2.184-5.271 5.149-7.072 8.808-1.509 2.965-2.226 6.35-2.226 10.269v21.796c0 1.243 0 2.487-.015 3.746-.014 3.24-.03 6.58.073 9.849.176 5.655 2.285 10.674 6.253 14.883 4.7 4.975 10.63 7.52 17.644 7.564h.952c1.128 0 2.3.014 3.441.014 1.465 0 2.68-.014 3.822-.043h.176z"
                fill="#000"
            />
            <path
                d="M57.18 81.193l-.22-.043c-.146-.029-.278-.043-.424-.072-.366-.058-.776-.13-1.215-.232a10.15 10.15 0 01-5.067-3.052L34.25 60.28c-.484-.521-.967-1.056-1.45-1.577-1.157-1.258-2.343-2.56-3.514-3.847-2.826-3.124-4.423-6.783-4.73-10.905a18.293 18.293 0 013.66-12.453 18.197 18.197 0 0111.13-6.841 21.855 21.855 0 013.938-.376c5.14 0 9.679 2.025 13.5 6.002.382.405.762.81 1.128 1.215.074-.072.147-.16.22-.232.761-.824 1.552-1.692 2.46-2.487 2.753-2.444 6.077-3.905 9.884-4.368a21.174 21.174 0 012.328-.145c4.1 0 8.127 1.345 11.334 3.775 3.294 2.502 5.578 5.96 6.603 9.994 1.567 6.162.162 11.918-4.056 16.647-3.704 4.137-7.526 8.302-11.216 12.323a1555.287 1555.287 0 00-4.774 5.207c-.556.622-1.127 1.23-1.683 1.851l-3.339 3.645c-1.801 1.967-4.027 3.11-6.619 3.399l-.307.072H57.18v.014zM42.904 32.742c-.103.014-.22.014-.337.029-.366.029-.791.057-1.172.115-4.51.71-7.98 4.484-8.273 8.982-.176 2.82.659 5.221 2.563 7.304 5.256 5.77 10.6 11.614 15.784 17.283l5.096 5.569c.396.434.879.665 1.362.665.483 0 .966-.231 1.376-.68 1.919-2.097 3.837-4.18 5.74-6.277 4.935-5.395 10.045-10.977 15.038-16.473 2.592-2.85 3.31-5.988 2.197-9.633-1.26-4.122-5.067-6.884-9.474-6.884-.41 0-.82.029-1.23.072-2.401.275-4.364 1.244-6.004 2.95-1.01 1.056-2.02 2.17-2.987 3.24-.454.507-.922 1.013-1.376 1.519-.908.998-2.094 1.547-3.31 1.547-1.215 0-2.4-.55-3.309-1.547l-.6-.65c-1.127-1.23-2.284-2.489-3.426-3.747-2.006-2.198-4.437-3.283-7.658-3.384z"
                fill="#000"
            />
        </svg>
    )
}

export default Heart
