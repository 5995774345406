import React, { useEffect } from 'react';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './screens/Home';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Terms from './screens/Terms';
import Privacy from './screens/Privacy';
import ScrollToTop from './components/ScrollToTop';
import mixpanel from 'mixpanel-browser'

function App() {
  
  useEffect(() => {
    // @ts-ignore
    mixpanel.init('479cbedd7bc0c84ad12f450373f35f97')
    mixpanel.track("Visit landing page")
  }, [])

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <NavBar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/terms-of-service">
            <Terms />
          </Route>
          <Route path="/privacy-policy">
            <Privacy />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
