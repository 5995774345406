import React from 'react'
import styled from 'styled-components';
import ScConnectionWeb from '../../assets/web/smart-connection-web.svg'
import ScConnectionMobile from '../../assets/mobile/smart-connection-mobile.svg'
import Content from '../../components/core/Content'
import { useWindowWidth } from '@react-hook/window-size'
// @ts-ignore
import Fade from 'react-reveal/Fade'

const CustomContainer = styled.div`
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 48px;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        padding: 24px;
        padding-top: 0;
    }

    .row {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }

        .col {
            width: 48%;
            @media screen and (max-width: 1024px) {
                max-width: 620px;
                flex-direction: column;
                width: 100%;
            }
        }
    }
`;

export default function Connection() {
    const width = useWindowWidth();
    const mobile = width <= 768
    return (
        <CustomContainer id="Connections">
            <div className='row'>
                <div className='col'>
                    <Fade bottom>
                        <Content
                            title="Make smart connections."
                            body="College and university are for meeting friends and collaborators to last a lifetime, right? But meeting new people can be stressful, especially when a pandemic is keeping you away from campus. With Mighty, connect with like-minded people who share your interests, or step outside your echo chamber and meet someone totally different!
                    "
                            caption="Explore and exchange ideas while interacting the way you want.  ✨"
                            titleHighlight="OrangeLight"
                            mb={6}
                        />
                    </Fade>
                </div>
                <div className='col'>
                    <Fade bottom>
                        <img src={mobile ? ScConnectionMobile : ScConnectionWeb} style={{ width: '100%', objectFit: "contain" }} />
                    </Fade>
                </div>
            </div>
        </CustomContainer>
    )
}