import React from "react"

function Line() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={4}
            viewBox="0 0 40 4"
            fill="none"
        >
            <path stroke="#6839F1" strokeWidth={4} d="M0 2h40" />
        </svg>
    )
}

export default Line
