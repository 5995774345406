import React from 'react'
import styled from 'styled-components';
import { Container } from 'react-bootstrap'

const StyledContainer = styled(Container)`
    line-height: 1.5;
    font-family: "Inter", Arial, Helvetica, sans-serif;

    .title {
        text-align: center;
        font-weight: 700;
        margin-bottom: 16px;
    }

    h3 {
        margin-top: 32px;
    }

    li strong {
        margin-bottom: 8px;
        display: inline-block;
    }
`;

export default function Terms() {
    return (
        <StyledContainer fluid="lg" className="pb-5" style={{ paddingTop: "7.25rem" }}>
            <h2 className='title'>Terms of Use</h2>

            <p className='last-updated'>Last Updated: <strong>February 19, 2021</strong></p>

            <p>The following terms of use (the “Terms of Use”) govern your access to and use of any mobile device software provided by us to you, including our Mighty mobile application (the “App”).</p>

            <p>These Terms of Use form an agreement between Sandbox Labs – Research and Development Inc. (“Sandbox”, “us”, “we”, “our”) and you. The term “you” or “User” refers to the person or entity browsing, installing, downloading, accessing, or otherwise using the App (“use” or “using” in these Terms of Use will mean any of the foregoing).</p>

            <p><strong>BY USING THE APP, YOU: (A) REPRESENT AND WARRANT THAT (I) YOU HAVE REACHED THE AGE OF MAJORITY IN YOUR JURISDICTION, (II) YOU HAVE THE CAPACITY TO ENTER INTO BINDING OBLIGATIONS, AND (III) ALL INFORMATION SUPPLIED BY YOU TO US THROUGH THE APP IS TRUE, ACCURATE, CURRENT, AND COMPLETE; AND (B) AGREE TO BE BOUND BY AND COMPLY WITH THESE TERMS OF USE, AS UPDATED FROM TIME TO TIME IN ACCORDANCE WITH SECTION 1.</strong></p>

            <p>This Agreement does not alter in any way the terms or conditions of any other agreement you may have with us in respect of any products, applications, services or otherwise.</p>

            <ol>
                <li>
                    <strong>Changes to these Terms of Use and the Sandbox App</strong>
                    <p>(a) Except where prohibited by applicable law, we reserve the right to change these Terms of Use at any time by posting a new version on our website or on the App. It is your obligation to monitor the App for any such changes. Your continued access to or use of the App after any changes to these Terms of Use indicates your acceptance of such changes. It is your responsibility to review these Terms of Use regularly.</p>
                    <p>(b) We reserve the right to change the App at any time, without notice. We may, at our discretion, suspend your access to or use of the App or any component thereof: (i) for scheduled maintenance; (ii) if you violate any provision of these Terms of Use; or (iii) to address any emergency security concerns.</p>
                </li>
                <li>
                    <strong>User Account</strong>
                    <p>(a) To access certain features of the App, you may be required to successfully sign up for a user account using the available interfaces of the Services, and be issued with a username and password login credentials (the “User ID”).</p>
                    <p>(b) If you are issued with a User ID, you will keep your User ID secure and will not grant access to or otherwise share your User ID with any other person.</p>
                    <p>(c) We reserve the right to disable any User ID issued to you at any time in our sole discretion. If we disable access to a User ID issued to you, you may be prevented from accessing the App.</p>
                    <p>(d) Sandbox is entitled to act on instructions received through your account. Sandbox is not responsible for any actions taken by your account by any other party using your User ID.</p>
                </li>
                <li>
                    <strong>Electronic Communications</strong>
                    <p>(a) When you use or view the App or send e-mails, texts or other electronic messages to us, you are communicating with us electronically and you consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on the App. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
                    <p>(b) By creating an account to access the App, you acknowledge and agree that Sandbox will send you service-related e-mails relating to your account, including service updates. These communications can be managed through user features made available through the App from time to time.</p>
                    <p>(c) If you are a member of our mailing list you will also receive email communications from us regarding our products, services, and initiatives (including collaborations and partnerships). If you do not wish to receive these communications, you can unsubscribe from such promotional e-mails at any time by clicking on the unsubscribe link in any of our e-mail communications.</p>
                </li>
                <li>
                    <strong>User Data and Privacy</strong>
                    <p>(a) You grant to us an irrevocable, worldwide, non-exclusive, royalty-free, transferable, and sublicensable license to access, collect, store, and use any data, information, records, or files that you load, transmit to, or enter into, or that we collect from, the App (collectively, “<strong>User Data</strong>”): (i) to make the App available and to develop and enhance its features and functions; and (ii) to produce data, information, or other materials that are not identified as relating to a particular individual or company (such data, information and materials, the “<strong>Aggregated Statistical Information</strong>”).</p>
                    <p>(b) We are free to create, use and disclose Aggregated Statistical Information during and after the Term for any purpose and without obligations of any kind.</p>
                    <p>(c) We reserve the right to remove, edit, limit, or block access to the User Data at any time, and we have no obligation to display or review your User Data.</p>
                    <p>(d) Please review our current Privacy Policy which contains important information about our practices in collecting, storing, using, and disclosing information about identifiable individuals (“<strong>Personal Information</strong>”), and which is hereby incorporated into and forms a part of these Terms of Use.</p>
                    <p>You represent and warrant to us that your User Data will only contain Personal Information in respect of which you have provided all necessary notices and disclosures, obtained all applicable third party consents and permissions and otherwise have all authority, in each case as required by applicable laws, to enable us to make available the App.</p>
                </li>
                <li>
                    <strong>Safety</strong>
                    <p>(a) Sandbox is not responsible for the conduct of any users on or off the App. You are responsible for your interactions with other users. You agree to review and follow our “<strong>Code of Conduct</strong>” prior to using the App.</p>
                </li>
                <li>
                    <strong>Ownership of the Sandbox App</strong>
                    <p>(a) All right, title, and interest, including intellectual property rights, in the App and all other materials provided by us hereunder, and any updates, adaptation, translation, customization, or derivative works thereof, will remain the sole property of Sandbox.</p>
                    <p>The App and all materials provided by us hereunder are made available and not “sold” to you.</p>
                    <p>(c) The Software and all other materials provided by us hereunder, including content we make available through or in the App, are protected by copyright in Canada, the United States, and elsewhere in the world pursuant to the Berne Convention. You are prohibited from modifying, copying, reproducing, publishing, posting, transmitting, distributing, creating derivative works from, decompiling, transferring, or selling any of the App, the Software, or other materials provided by us hereunder, or sharing or granting access in any of the foregoing to any third party for any purpose.</p>
                    <p>All rights not expressly granted to you in these Terms of Use are reserved by Sandbox.</p>
                </li>
                <li>
                    <strong>Your Responsibilities</strong>
                    <p>You agree to:</p>
                    <p>(a) keep your User ID and all other login information confidential;</p>
                    <p>(b) monitor and control all activity conducted through your account in connection with the App;</p>
                    <p>(c) promptly notify us if you become aware or reasonably suspect any illegal or unauthorized activity or a security breach involving your account, including any loss, theft, or unauthorized disclosure or use of a User ID or account;</p>
                    <p>(d) upload and disseminate only data to which you own all required rights under law and do so only consistent with applicable law;</p>
                    <p>(e) use reasonable efforts to prevent unauthorized access to or use of the App;</p>
                    <p>(f) keep your contact information available through the App up-to-date; and</p>
                    <p>(g) comply with all applicable laws and regulations, including, but not limited to, all intellectual property, data, privacy, and any export control laws.</p>
                </li>
                <li>
                    <strong>No Unlawful or Prohibited Use</strong>
                    <p>You will not use the App in violation of these Terms of Use or of any applicable law. You will not, without our prior written permission, use the App for any purpose other than to access and use the services we make available through the App. Without limiting the generality of the foregoing, you will not (and will not attempt to) directly or indirectly:</p>
                    <p>(a) disable, overly burden, impair, or otherwise interfere with servers or networks connected to the App (e.g., a denial of service attack);</p>
                    <p>(b) attempt to gain unauthorized access to the App, or bypass any measures we may use to prevent or restrict access to the App;</p>
                    <p>(c) send, upload, collect, transmit, store, use, post, publish, or otherwise communicate on the App any data, information, pictures, videos, audio or other materials or content that: (i) contains any computer viruses, worms, malicious code, or any software intended to damage or alter a computer system or data; (ii) you do not have the lawful right to send, upload, collect, transmit, store, use, post, publish, or otherwise communicate; (iii) is false, intentionally misleading, or impersonates any other person; (iv) is defamatory, bullying, harassing, abusive, threatening, vulgar, obscene, or offensive, or that contains pornography, nudity, or graphic or gratuitous violence, or that promotes violence, racism, discrimination, bigotry, hatred, or physical harm of any kind against any group or individual; (v) is harmful to minors in any way or targeted at minors; (vi) infringes, violates, or otherwise misappropriates the intellectual property or other rights of any third party (including any moral right, privacy right, or right of publicity); (vii) encourages any conduct that may violate any applicable laws or would give rise to civil or criminal liability; or (viii) is designed or intended to obtain password, account, or private information from any other App user.</p>
                    <p>(d) Share, transfer, or otherwise provide access to an account designed for you to another person;</p>
                    <p>(e) Use any data mining, robots, or similar data gathering or extraction methods, or copy, modify, reverse engineer, reverse assemble, disassemble, or decompile the App or any part thereof or otherwise attempt to discover any source code;</p>
                    <p>(f) Use the App for the purpose of building a similar or competitive product or service; or</p>
                    <p>(g) Authorize, permit, enable, induce, or encourage any third party to do the above.</p>
                </li>
                <li>
                    <strong>Communications</strong>
                    <p>You are solely responsible for your interactions with other users of the App, including any communications that you exchange through the App Anonymous Chat, or direct messages to other users. Sandbox reserves the right, but has no obligation, to review disputes between you and other App users. This includes the right to review messages exchanged through the App Anonymous Chat or direct messages, based on any reports that Sandbox receives alleging the violation of these Terms of Use through the use of the App Anonymous Chat or direct messages, including reports regarding alleged harassment, indecency, and offensive messages.</p>
                </li>
                <li>
                    <strong>Communications Not Confidential</strong>
                    <p>We do not guarantee the confidentiality of any communications made by you through the App, even communications made under the “Anonymous Chat” feature. We do not guarantee the security of data transmitted over the Internet or public networks in connection with your use of the App.</p>
                </li>
                <li>
                    <strong>Third Party Content, Websites, or Services</strong>
                    <p>The App may provide links or access to third party content, websites, or services. Likewise, we may allow you to access the App from third party systems. Sandbox does not endorse any third party content, websites, services, or systems, or guarantee their quality, accuracy, reliability, completeness, currency, timeliness, non-infringement, merchantability, or fitness for any purpose. Third party content, websites, services, or systems are not under Sandbox’s control, and if you choose to access any such content, websites, or services, or to access the App from such systems, you do so entirely at your own risk. You acknowledge that you may be required to accept terms of use applicable to third party content, websites, services, or systems and agree to accept and comply with any such terms of use.</p>
                </li>
                <li>
                    <strong>Malicious Code and Security</strong>
                    <p>The downloading and viewing of content is done at your own risk. We do not guarantee or warrant that the App is compatible with your mobile phone system or that the App, or any links from the App, will be free of viruses, worms, trojan horses, or disabling devices or other code that manifests contaminating or destructive properties. You are responsible for implementing safeguards to protect the security and integrity of your mobile phone system, and you are responsible for the entire cost of any service, repairs, or connections of and to your mobile phone system that may be necessary as a result of your use of the App.</p>
                    <p>You are prohibited from attempting to circumvent and from violating the security of the App including without limitation: (a) accessing content that is not intended for you; (b) attempting to breach or breaching the App security or authentication measures; (c) restricting, disrupting, or disabling service to App users, hosts, servers, or networks by any means, or (d) otherwise attempting to interfere with the proper working of the App, including but not limited to by introducing any material that is malicious or technologically harmful.</p>
                </li>
                <li>
                    <strong>Disclaimer</strong>
                    <p>THE LAWS OF CERTAIN JURISDICTIONS, INCLUDING QUEBEC, DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LEGAL WARRANTIES, CONDITIONS OR REPRESENTATIONS. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE EXCLUSIONS OR LIMITATIONS IN THESE TERMS OF USE (INCLUDING THE FOLLOWING DISCLAIMERS) MAY NOT APPLY AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
                    <p>YOU ACKNOWLEDGE, UNDERSTAND, AND AGREE THAT THE APP IS PROVIDED “AS IS” AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND WITH RESPECT TO THE APP WHETHER EXPRESS, IMPLIED, STATUTORY, OR COLLATERAL, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES AND CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, COMPATIBILITY, TITLE, SECURITY, RELIABILITY, COMPLETENESS, QUIET ENJOYMENT, ACCURACY, RELIABILITY, CURRENCY, TIMELINESS, QUALITY, INTEGRATION, FITNESS FOR A PARTICULAR PURPOSE, AND NON- INFRINGEMENT, OR ANY WARRANTIES OR CONDITIONS ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE, OR THAT THE APP IS OR WILL BE ERROR-FREE OR WILL OPERATE WITHOUT INTERRUPTION. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND WITH RESPECT TO THIRD PARTY COMMUNICATIONS AND ANY THIRD-PARTY WEBSITES OR CONTENT DIRECTLY OR INDIRECTLY ACCESSED THROUGH THE APP.</p>
                </li>
                <li>
                    <strong>Limitation of Liability</strong>
                    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE BE LIABLE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER LEGAL THEORY, FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES; OR LOST PROFITS, LOSS OF USE, LOSS OF DATA, PERSONAL INJURY, FINES, FEES, PENALTIES, OR OTHER LIABILITIES, IN EACH CASE, WHETHER OR NOT WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM OR RELATED TO THE APP OR THESE TERMS OF USE.</p>
                    <p>FOR ANY OTHER DAMAGES, OR TO THE EXTENT THAT THE FOREGOING LIMITATION IS NOT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL OUR TOTAL AGGREGATE LIABILITY IN CONNECTION WITH OR UNDER THESE TERMS OF USE, OR YOUR USE OF, OR INABILITY TO MAKE USE OF, THE APP, EXCEED $50 CAD. FOR GREATER CERTAINTY, THE EXISTENCE OF ONE OR MORE CLAIMS UNDER THESE TERMS OF USE WILL NOT INCREASE THIS MAXIMUM LIABILITY AMOUNT.</p>
                </li>
                <li>
                    <strong>Indemnification</strong>
                    <p>You will defend, indemnify, and hold harmless us and our respective officers, directors, employees, and agents, and any licensees, successors, and assignees from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties, or other costs or expenses of any kind or nature including reasonable legal and accounting fees, arising out of or in connection with:</p>
                    <p>(a) your User Data;</p>
                    <p>(b) your use (or the use by any third party using your User ID) of the App;</p>
                    <p>(c) your breach of any provision of these Terms of Use or any documents referenced herein; or</p>
                    <p>(d) your violation of any law or the rights of a third party (including intellectual property rights).</p>
                </li>
                <li>
                    <strong>Term and Termination; Survival</strong>
                    <p>(a) These Terms of Use will commence on the day you first use the App and will remain in effect until terminated by either party in accordance with the provisions of these Terms of Use (the “Term”). We may terminate these Terms of Use at any time and with immediate effect by giving notice to you, at our discretion, by email (at your current email address on file with us) or through the App. You may terminate these Terms of Use at any time and with immediate effect by requesting by email that your User ID be deleted, or by deleting your account by accessing the App settings and selecting “Delete account”, ceasing use of the App and uninstalling and deleting the App. For greater certainty, if you continue to use any portion of the App that is publicly available after these Terms of Use have been terminated, these Terms of Use will continue to apply to the extent of such use.</p>
                    <p>(b) The following Sections, together with any other provision of these Terms of Use which expressly or by its nature survives termination or expiration, or which contemplates performance or observance subsequent to termination or expiration of these Terms of Use, will survive expiration or termination of these Terms of Use for any reason: Sections 4 (User Data and Privacy), 6 (Ownership of the App), 10(Communications Not Confidential), 11 (Third Party Content, Websites or Services) 12(Malicious Code and Security), 13 (Disclaimer), 14 (Limitation of Liability),15 (Indemnification), 16 (Survival), and 17 (General Provisions).</p>
                </li>
                <li>
                    <strong>General Provisions</strong>
                    <p>(a) <u>Choice of Law.</u> Except as restricted by applicable law, these Terms of Use will be governed by the laws of the Province of Ontario and the federal laws of Canada applicable therein without giving effect to any principles of conflicts of law and such laws apply to your access to or use of the App, notwithstanding your domicile, residency, or physical location. You will only use the App in jurisdictions where the App may lawfully be used. Except as restricted by applicable law, you hereby consent to the exclusive jurisdiction and venue of courts in Toronto, Ontario, Canada in all disputes arising out of or relating to the use of the App.</p>
                    <p>(b) <u>Entire Agreement.</u> These Terms of Use constitute the entire agreement between you and us pertaining to the subject matter hereof and supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us with respect to the App. A printed version of these Terms of Use and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to these Terms of Use to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
                    <p>(c) <u>Waiver.</u> Our failure to insist upon or enforce strict performance of any provision of these Terms of Use will not be construed as a waiver of any provision or right. A waiver of any provision of these Terms of Use must be in writing and a waiver in one instance will not preclude enforcement of such provision on other occasions.</p>
                    <p>(d) <u>Severable.</u> If any of the provisions contained in these Terms of Use are determined to be void, invalid, or otherwise unenforceable by a court of competent jurisdiction, such provision will be severed from these Terms of Use and all other provisions of these Terms of Use will remain in full force and effect.</p>
                    <p>(e) <u>Assignment.</u> You will not assign these Terms of Use to any third party without our prior written consent. We may assign these Terms of Use or any rights under these Terms of Use to any third party without your consent. Any assignment in violation of this Section will be void. The terms of these Terms of Use will be binding upon permitted assignees. These Terms of Use will inure to the benefit of and be binding upon the parties, their permitted successors, and permitted assignees.</p>
                    <p>(f) <u>English Language.</u> It is the express wish of the parties that these Terms of Use and all related documents be drawn up in English. C’est la volonté expresse des parties que la présente convention ainsi que les documents qui s’y rattachent soient rédigés en anglais.</p>
                </li>
                <li>
                    <strong>Contact</strong>
                    <p>If you have any questions or comments regarding these Terms of Use, please contact us at <a href='mailto:support@sandboxlabs.ca' target='blank'>support@sandboxlabs.ca</a>.</p>
                </li>
                <li>
                    <strong>Apple App Store Additional License Terms</strong>
                    <p>If the App is provided to you through the Apple Inc. (Apple Inc. together with all of its affiliates, “Apple”) App Store, the following terms and conditions apply to you in addition to all the other terms and conditions of these Terms of Use:</p>
                    <p>The parties acknowledge these Terms of Use are concluded between the parties, and not with Apple. The responsibility for the App and content thereof is governed by these Terms of Use.</p>
                    <p>Notwithstanding anything to the contrary hereunder, you may use the App only on an iPhone or iPod touch that you own or control.</p>
                    <p>You and we acknowledge that Apple has no obligation to furnish any maintenance or support services with respect to the App.</p>
                    <p>In the event of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the App (if any) to you. Except for the foregoing, to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be governed by these Terms of Use.</p>
                    <p>Any claim in connection with the App related to product liability, a failure to conform to applicable legal or regulatory requirements, or claims under consumer protection or similar legislation is governed by these Terms of Use, and Apple is not responsible for such claim.</p>
                    <p>Any third party claim that the App or your possession and use of the App infringes that third party’s intellectual property rights will be governed by these Terms of Use, and Apple will not be responsible for the investigation, defense, settlement and discharge of such intellectual property infringement claim.</p>
                    <p>You represent and warrant that you are not: (i) located in any country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; or (ii) listed on any U.S. Government list of prohibited or restricted parties.</p>
                    <p>You may contact us in writing regarding any notices, questions, complaints or claims with respect to the App at support@sandboxlabs.ca.</p>
                    <p>Apple is a third party beneficiary to these Terms of Use and may enforce these Terms of Use against you.</p>
                    <p>If any of the terms and conditions in these Terms of Use are inconsistent or in conflict with Apple’s applicable instructions for Minimum Terms for Developer’s End User License Agreement (the current version as of the date these Terms of Use was last updated is located at: <a href='https://www.apple.com/legal/internet-services/itunes/dev/minterms' target='blank'>http://www.apple.com/legal/internet-services/itunes/appstore/dev/minterms/</a>) or the App Store Terms of Service (the current version as of the date these Terms of Use was last updated is located at: <a href='https://www.apple.com/legal/internet-services/itunes/ca/terms.html' target='blank'>http:/ www.apple.com/legal/internet-services/itunes/ca/terms.html</a>), the terms and conditions of Apple’s instructions for Minimum Terms for Developer’s End User License Agreement or App Store Terms of Service, as applicable, will apply to the extent of such inconsistency or conflict.</p>
                </li>
                <li>
                    <strong>Google Play</strong>
                    <p>If the App is provided to you through the Google Inc. (Google Inc. together with all of its affiliates, “Google”) Google Play store, the following terms and conditions apply to you in addition to all the other terms and conditions of these Terms of Use:</p>
                    <p>You acknowledge that Google is not responsible for providing support services for the App.</p>
                    <p>If any of the terms and conditions in these Terms of Use are inconsistent or in conflict with the Google Play Developer Distribution Agreement (the current version as of the date these Terms of Use was last updated is located at <a href='https://play.google.com/about/developer-distribution-agreement.html' target='blank'>https://play.google.com/about/developer-distribution-agreement.html</a>), the terms and conditions of Google’s Google Play Developer Distribution Agreement will apply to the extent of such inconsistency or conflict.</p>
                </li>
            </ol>
        </StyledContainer>
    )
}
