import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme'
import { Link as ReactLink } from 'react-router-dom'
import Mighty from '../icons/Mighty'

const Container = styled.div`
    background-color: ${theme.palette.Ink};
    padding: 3.75rem 0 3.75rem 3.75rem;
    display: flex;
    justify-content: center;

    .content {
        width: 100%;
        max-width: 1140px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`
const Link = styled(ReactLink)`
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.18;
    color:${theme.palette.White};
    margin-right:3.75rem;
    :hover{
        color:${theme.palette.White};
        text-decoration:underline solid ${theme.palette.White};
    }
`
const Email = styled.a`
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.18;
    color:${theme.palette.White};
    margin-right:3.75rem;
    :hover{
        color:${theme.palette.White};
        text-decoration:underline solid ${theme.palette.White};
    }
`
const Company = styled.p`
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.18;
    color:${theme.palette.White};
    margin-top:2.25rem;
    margin-bottom:0;
`
export default function Desktop() {
    return (
        <Container>
            <div className='content'>
                <div>
                    <ReactLink to="/">
                        <Mighty />
                    </ReactLink>
                    <Company> Sandbox Labs Research & Development</Company>
                </div>
                <div style={{ alignSelf: "flex-end" }}>
                    <Link to="/terms-of-service">Terms of Service</Link>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Email href="mailto:support@sandboxlabs.ca">Contact Us</Email>
                </div>
            </div>
        </Container>
    )
}
