import { useWindowWidth } from '@react-hook/window-size';
import styled from 'styled-components';
import React from 'react'
import DegreeProgressWeb from '../../assets/web/degree-progress-web.svg'
import DegreeProgressMobile from '../../assets/mobile/degree-progress-mobile.svg'
import Content from '../../components/core/Content'
//@ts-ignore
import Fade from 'react-reveal/Fade'

const CustomContainer = styled.div`
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 48px;

    @media screen and (max-width: 1024px) {
        padding: 24px;
    }

    .row {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1024px) {
            margin-top: 30px;
            flex-direction: column;
        }

        .col {
            width: 48%;
            @media screen and (max-width: 1024px) {
                max-width: 620px;
                flex-direction: column;
                width: 100%;
            }
        }
    }
`;

export default function Progress() {

    const width = useWindowWidth();
    const mobile = width <= 768
    return (
        <CustomContainer id="Progress">
            <div className='row'>
                <div className='col'>
                    <Fade bottom>

                        <Content
                            title="Stay on track and in the loop."
                            body="Sign up with your student email and find the classes you’re enrolled in each semester. You’ll immediately be connected to your classmates and see a visualization of yours and others’ degrees. In each “classroom” you’ll see who shares other courses with you—DM your classmates solo or in groups, or use the anonymous chat to ask questions in class anxiety-free."
                            caption="It’s campus life at your virtual fingertips. 👋
                            "
                            titleHighlight="BlueLight"
                            mb={6}
                        />
                    </Fade>
                </div>
                <div className='col'>
                    <Fade bottom>
                        <img src={mobile ? DegreeProgressMobile : DegreeProgressWeb} style={{ width: '100%', objectFit: "contain" }} />
                    </Fade>
                </div>
            </div>
        </CustomContainer>
    )
}
