import React from "react"
type Props = {
    color?: string
}
function Close({ color }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={26}
            height={26}
            viewBox="0 0 26 26"
            fill="none"
        >
            <path
                d="M24.77 3.273a1 1 0 000-1.414L23.618.707a1 1 0 00-1.414 0l-8.759 8.758a1 1 0 01-1.414 0L3.273.707a1 1 0 00-1.414 0L.707 1.86a1 1 0 000 1.414l8.758 8.758a1 1 0 010 1.414L.707 22.204a1 1 0 000 1.414L1.86 24.77a1 1 0 001.414 0l8.758-8.759a1 1 0 011.414 0l8.759 8.759a1 1 0 001.414 0l1.152-1.152a1 1 0 000-1.414l-8.759-8.759a1 1 0 010-1.414l8.759-8.758z"
                fill={color ? color : "#fff"}
            />
        </svg>
    )
}

export default Close
