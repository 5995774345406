import styled from 'styled-components'
import React from 'react'
import { Container as BsContainer, Col } from 'react-bootstrap'
import BackgroundImage from '../../assets/core/background.png'
import Text from '../../components/core/Text'
import AppleStore from '../../components/core/AppleStore'
import GooglePlay from '../../components/core/GooglePlay'
import PablitaSocial from '../../assets/core/Pablita-Social-Network.png'
import phoneWeb from '../../assets/web/phone-web.svg'
import phoneMobile from '../../assets/mobile/phone-mobile.svg'
import curveHeader from '../../assets/mobile/Header.png'
import mixpanel from 'mixpanel-browser'
import {
    useWindowWidth,
} from '@react-hook/window-size'

const CustomContainer = styled(BsContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:8rem;
    height: 100vh;
    position: relative;
    margin-bottom: 0;
    overflow: hidden;

    @media screen and (max-width:768px){
        padding: 6.625rem 1.5rem 0rem 1.5rem;
        margin-bottom:0;
        height: auto;
        padding-bottom: 0;
    }
`

const Row = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1170px;
    height: 100%;

    .mt-lg-4 {
        margin-bottom: 2em;
    }

    .col {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .body1 {
            max-width: 700px;
        }

        .button-container {
            > :first-child {
                margin-right: 1em;
            }
        }
    }

    .col1 {
        flex-grow: 6;
    }

    .col2 {
        flex-grow: 4;
    }

    ${({mobile}: CurveProps) => {
        if (mobile) {
            return `
                flex-direction: column;

                .col {
                    height: auto;
                    display: block;
                    padding: 0;

                    .body1 {
                        max-width: 600px;
                    }

                    .button-container {
                        justify-content: center;
                        margin-bottom: 2em;

                        > :first-child {
                            margin-right: 1em;
                        }
                    }
                }
            `;
        }
    }}
`

const PhoneImage = styled.img`
    object-fit: contain;
    height: 85%;
    max-height: 756px;
    position: absolute;
    bottom: 0;

    @media screen and (max-width: 768px) {
        position: static;
        max-width: 435px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
`;

const Background = styled.div`
    background-repeat: no-repeat;
    background-position: center;
    background-image:url(${BackgroundImage});
    background-size: cover;
    width:100%;
`

type CurveProps = {
    mobile: boolean
}

const Curve = styled.div`
    width:100%;
    height: 71vw;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${curveHeader});
    background-size: cover;
    display: flex;
    justify-content: center;

    .text-container {
        width: calc(100% - 50px);
        max-width: 600px;
        position: absolute;
        top: 34%;
        left: 50%;
        transform: translate(-50%, -70%);
    }

    ${({mobile}: CurveProps) => {
        if (!mobile) {
            return `
                height: auto;
                background-color: #6839F1;
                box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.25);

                .text-container {
                    position: static;
                    transform: none;
                    padding: 60px 0;
                }
            `;
        }
    }}
`

const Image = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
    padding: 0 21px;
`

const PeopleImageContainer = styled.div`
    width: 100%;
    padding: 70px 70px 0 70px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding: 0
    }

    img {
        max-width: 811px;
    }
`;

export default function Header() {
    const width = useWindowWidth();
    const mobile = width <= 768
    return (
        <>
            <Background>
                <CustomContainer fluid>
                    <Row mobile={mobile}>
                        <div className='col col1'>
                            <Text varient="Header1" color="White">
                                Meet your class.
                            </Text>
                            <Text varient="Header1" color="White">
                                Find your community.
                            </Text>
                            <div className="mt-4 mt-lg-4 body1">
                                <Text varient="Body1" color="White">
                                    Mighty is a social app designed for student life. Create an online network with other students, chat with your class, and track your degree.
                                </Text>
                            </div>
                            <div className="d-flex flex-row align-items-center justify-content-lg-start button-container">
                                <div onClick={() => mixpanel.track("Download-IOS", { location: "header" })}>
                                    <a href="https://apps.apple.com/ca/app/mighty/id1551423127" target="_blank" rel="noreferrer">
                                        <AppleStore size={mobile ? 41 : 50}/>
                                    </a>
                                </div>
                                <div onClick={() => mixpanel.track("Download-Android", { location: "header" })}>
                                    <a href="https://play.google.com/store/apps/details?id=com.sandboxlabs.Mighty" target="_blank" rel="noreferrer">
                                        <GooglePlay size={mobile ? 41 : 50}/>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='col col2'>
                            <PhoneImage src={mobile ? phoneMobile : phoneWeb} />
                        </div>
                    </Row>

                </CustomContainer>
            </Background>
            <Curve mobile={mobile}>
                <div className='text-container'>
                    <Text varient="Subheading1" color="White" textAlign='center'>
                        FYI - We're testing things out at UofT right now. We'll be available at more schools soon ✌️
                    </Text>
                </div>
            </Curve>
            
            <PeopleImageContainer>
                <Image src={PablitaSocial} />
            </PeopleImageContainer>
        </>
    )
}
